import React from 'react';

import { motion } from 'framer-motion';

import customTwMerge from '../../../utils/twMerge';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const Container = ({ children, className }: Props) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState<number | 'auto'>('auto');

  React.useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const observedHeight = entries[0]?.contentRect.height;
      setHeight(observedHeight || 0);
    });

    resizeObserver.observe(containerRef.current);
    // eslint-disable-next-line
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <motion.div
      className={customTwMerge('overflow-hidden', className)}
      style={{ height }}
      animate={{ height }}
      transition={{ duration: 0.2, type: 'spring' }}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  );
};

export default Container;
