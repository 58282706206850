import React, { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as ErrorIcon } from './icons/error.svg';

export const MessageTypes = {
  ERROR: 'ERROR',
};
const StyledContainer = styled.div`
  ${tw`border rounded-lg border-current flex pl-4 pr-6 py-4 items-start`}

  svg {
    ${tw`mr-2 mt-1 flex-shrink-0`}
  }
  p {
    ${tw`p-0 m-0 flex-1 text-sm font-medium leading-6 text-justify`}
  }
`;
const ErrorContainer = styled(StyledContainer)`
  color: var(--error-text);
  background-color: var(--error-bg);
`;

type Props = React.PropsWithChildren<{
  className?: string;
  type?: string;
}>;

const Message = ({ className, type = MessageTypes.ERROR, children }: Props): JSX.Element => {
  const Component = useMemo(() => {
    if (type === MessageTypes.ERROR) {
      return ErrorContainer;
    }

    return StyledContainer;
  }, [type]);
  const icon = useMemo(() => {
    if (type === MessageTypes.ERROR) {
      return <ErrorIcon />;
    }

    return null;
  }, [type]);
  return (
    <Component className={className}>
      {icon}
      <p>{children}</p>
    </Component>
  );
};

Message.MessageTypes = MessageTypes;

export default Message;
