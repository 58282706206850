import tw from 'twin.macro';
import styled from 'styled-components';

const DateRangePickerContainer = styled.div`
  ${tw`relative pointer-events-auto`}

  .DayPicker {
    ${tw`w-80`}
  }
  .DayPicker-Weekdays {
    ${tw`block m-0`}
  }

  .DayPicker-Month {
    ${tw`m-0 w-full flex flex-col items-center`}
  }

  .DayPicker-Week,
  .DayPicker-WeekdaysRow {
    ${tw`grid gap-2 mx-1`}
    grid-template-columns: repeat(7, 2rem);
  }

  .DayPicker-Week {
    background-color: var(--dark-blue-5);
  }

  .DayPicker-Day.DayPicker-Day--outside,
  .DayPicker-Day:not(.DayPicker-Day--selected) {
    ${tw`bg-white rounded-none -mx-1`}
  }

  .DayPicker-wrapper {
    ${tw`p-0`}
  }

  .DayPicker-Weekday {
    ${tw`text-sm font-semibold`}
  }

  .DayPicker-Day {
    ${tw`text-sm rounded`}
    color: var(--dark-black);
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    color: var(--dark-blue);
    ${tw`rounded-none bg-transparent`}
  }

  .DayPicker-Day--selected.DayPicker-Day--start::before {
    content: '';
    ${tw`absolute bg-white top-0 w-1 h-full -left-1`}
  }

  .DayPicker-Day--selected.DayPicker-Day--end::after {
    content: '';
    ${tw`absolute bg-white top-0 w-1 h-full left-full`}
  }

  .DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside),
  .DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside) {
    background-color: var(--dark-blue);
    color: var(--white);
    ${tw`font-semibold`}
  }

  .DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside)
    + .DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside) {
    ::before {
      content: '';
      ${tw`w-3 h-full absolute -left-2 top-0`}
      background-color: var(--dark-blue);
    }
  }

  .DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    ${tw`rounded-r-none`}
  }

  .DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--start):not(.DayPicker-Day--outside) {
    ${tw`rounded-l-none`}
  }

  .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside):hover {
    background-color: var(--dark-blue);
    color: var(--white);
  }

  .DayPicker-Day--disabled {
    ${tw`text-grayscale-16 cursor-not-allowed`}
  }
`;

export default DateRangePickerContainer;
