import React from 'react';
import { classNames as cn } from 'utils/src/classnames';
import * as PrimitiveModal from '@radix-ui/react-dialog';
import { ModalProvider } from './hooks/use-modal';
import Text from '../text';

type RootProps = {
  children: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (arg0: boolean) => void;
  modal?: boolean;
  id?: string;
} & PrimitiveModal.DialogProps;

export const Root = ({ children, ...props }: RootProps): JSX.Element => (
  <ModalProvider>
    <PrimitiveModal.Root {...props}>{children}</PrimitiveModal.Root>
  </ModalProvider>
);

type TitleProps = {
  children: string | React.ReactNode;
  className?: string;
};

export const Title = ({ children, className = 'font-medium text-grayscale-100' }: TitleProps): JSX.Element => (
  <PrimitiveModal.Title asChild>
    <div>
      <Text type="header-2" className={cn([className ?? ''])}>
        {children}
      </Text>
    </div>
  </PrimitiveModal.Title>
);

Title.displayName = 'MODAL_TITLE';

type SubTitleProps = {
  children: string;
  className?: string;
};

export const SubTitle = ({ children, className }: SubTitleProps): JSX.Element => (
  <Text type="body" size="md" className={cn(['text-grayscale-64 mb-6 mt-1', className ?? ''])} as="p">
    {children}
  </Text>
);

SubTitle.displayName = 'MODAL_SUBTITLE';

export * from './trigger';
export { useModal } from './hooks';
export { default as Footer } from './footer';
export { default as Content } from './content';
export default Root;
