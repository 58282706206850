import styled from 'styled-components';
import tw from 'twin.macro';

type Props = {
  text: string;
  className?: string;
};

const StyledContainer = styled.div`
  ${tw`flex items-center justify-center relative h-5 my-2`}
`;
const StyledText = styled.span`
  color: var(--separator-text);
`;
const StyledBar = styled.div`
  border-color: var(--mystic);
`;

const Separator = ({ text, className }: Props): JSX.Element => {
  return (
    <StyledContainer className={className}>
      <StyledBar className="flex-1 border-t border-gray-200" />
      <StyledText className="bg-white px-2 absolute">{text}</StyledText>
    </StyledContainer>
  );
};

export default Separator;
