/**
 * Get React component's name (known as displayName).
 *
 * @TODO Fix types
 *
 * @param child: react child. We're using any because we cannot cover all the React possibilities
 * @returns child component name (it'll return 'Component' as fallback)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const getChildName = (child: any): string => {
  return child.displayName || child.name || (child.type || {}).displayName || 'Component';
};
