/**
 * @generated SignedSource<<4ce2b0e04a3fdfa600feebfc08b15628>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type routesQuery$variables = {};
export type routesQuery$data = {
  readonly res: {
    readonly apiID: string;
    readonly email: string;
    readonly fullName: string;
  } | null;
};
export type routesQuery = {
  response: routesQuery$data;
  variables: routesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiID",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routesQuery",
    "selections": [
      {
        "alias": "res",
        "args": null,
        "concreteType": "AdminUser",
        "kind": "LinkedField",
        "name": "admin__Identity",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routesQuery",
    "selections": [
      {
        "alias": "res",
        "args": null,
        "concreteType": "AdminUser",
        "kind": "LinkedField",
        "name": "admin__Identity",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ac414d9fea523b2a781986dff66d097",
    "id": null,
    "metadata": {},
    "name": "routesQuery",
    "operationKind": "query",
    "text": "query routesQuery {\n  res: admin__Identity {\n    apiID\n    fullName\n    email\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "de1635c6fd4b0c35cf2e89ce6ad13340";

export default node;
