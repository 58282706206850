const grayscale = {
  100: '#161D2E',
  80: '#383B43',
  64: '#5E6269',
  48: '#96989E',
  24: '#BDC0C7',
  16: '#DEE0E5',
  8: '#F0F1F5',
  2: '#F7F8FA',
  0: '#FFFFFF',
  border: '#DEE0E5',
};

const indigo = {
  200: '#181442',
  100: '#211C5C',
  90: '#332E69',
  70: '#64608D',
  40: '#A6A4BE',
  20: '#D3D2DE',
  10: '#E9E8EF',
};

const pink = {
  200: '#AB0A46',
  100: '#ED0E61',
  70: '#F25690',
  40: '#F89FC0',
  20: '#FBCFDF',
  10: '#FDE7EF',
};

const blue = {
  200: '#2474B8',
  100: '#32A1FF',
  70: '#70BDFF',
  40: '#ADD9FF',
  20: '#D6ECFF',
  10: '#EBF6FF',
};

const red = {
  200: '#A12525',
  100: '#DF3333',
  40: '#F2ADAD',
  20: '#F9D6D6',
  10: '#FCEBEB',
};

const green = {
  200: '#28754E',
  100: '#37A36D',
  40: '#AFDAC5',
  20: '#D7EDE2',
  10: '#EBF6F0',
};

const yellow = {
  200: '#B88F01',
  100: '#FFC701',
  40: '#FFE999',
  20: '#FFF4CC',
  10: '#FFF8DC',
};

const orange = {
  200: '#A85E04',
  100: '#E98305',
  40: '#F6CD9B',
  20: '#FBE6CD',
  10: '#FDF3E6',
};

const coolgray = {
  200: '#4A5568',
  100: '#748094',
  40: '#C3CAD4',
  20: '#E5E9EF',
  10: '#EFF1F4',
};

const mint = {
  200: '#0A9373',
  100: '#0ECCA0',
  40: '#9FEBD9',
  20: '#CFF5EC',
  10: '#E7FAF5',
};

const purple = {
  200: '#322D6A',
  100: '#453F93',
  40: '#B2B0C9',
  20: '#DAD9E9',
  10: '#ECECF4',
};

const colors = {
  grayscale,
  indigo,
  pink,
  blue,
  red,
  green,
  yellow,
  orange,
  coolgray,
  mint,
  purple,
};

module.exports = colors;
