import React, { useEffect } from 'react';
import twMerge from '../../utils/twMerge';
import { classNames } from 'utils/src/classnames';
import * as PrimitiveModal from '@radix-ui/react-dialog';
import * as Icons from '@benepass/icons';
import { useModal } from './hooks/use-modal';

export const Close = (): JSX.Element => (
  <PrimitiveModal.Close asChild>
    <button type="button" aria-label="Close modal">
      <Icons.Close />
    </button>
  </PrimitiveModal.Close>
);

type Props = {
  hideCloseButton?: boolean;
  hideHeader?: boolean;
  position?: 'center' | 'left' | 'right';
  overlayClassName?: string;
  contentClassName?: string;
  titleContainerClassName?: string;
} & PrimitiveModal.DialogContentProps;

const Content = ({
  children,
  className,
  position = 'center',
  hideCloseButton = false,
  hideHeader = false,
  overlayClassName,
  contentClassName,
  titleContainerClassName,
  ...props
}: Props): JSX.Element => {
  const state = useModal();
  const titleElement = React.Children.toArray(children).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (child: any) => child.type?.displayName === 'MODAL_TITLE'
  );
  const filteredElements = React.Children.toArray(children).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (child: any) => !['MODAL_TITLE', 'MODAL_FOOTER'].includes(child.type?.displayName)
  );
  const [footerElement] = React.Children.toArray(children).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (child: any) => child.type?.displayName === 'MODAL_FOOTER'
  );
  const positionCenter = classNames([
    'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
    'min-h-fill-available md:min-h-0',
    'pb-7',
    'md:max-w-xl md:w-11/12 max-h-screen md:max-h-90vh w-full',
    'rounded-md',
  ]);
  const positionSide = classNames(['w-5/12', 'top-0 bottom-0', 'rounded-none py-5 px-10']);
  const positionLeft = classNames([positionSide, 'left-0 ']);
  const positionRight = classNames([positionSide, 'right-0']);

  const base = classNames([
    'bg-white',
    'fixed',
    'flex flex-col',
    'z-50',
    position === 'center' ? positionCenter : '',
    position === 'left' ? positionLeft : '',
    position === 'right' ? positionRight : '',
    className as string,
  ]);
  const classes = twMerge(base);
  useEffect(() => {
    if (!state.footer && footerElement) {
      state.setFooter(footerElement);
    }
  }, [state, footerElement]);
  const overlayClassNames = classNames([
    'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40',
    overlayClassName as string,
  ]);
  return (
    <PrimitiveModal.Portal>
      <PrimitiveModal.Overlay className={twMerge(overlayClassNames)} />
      <PrimitiveModal.Content {...props} className={classes}>
        {!hideHeader && (
          <div className={twMerge('flex w-full items-center mb-4 px-7 pt-7', titleContainerClassName)}>
            {titleElement?.length ? titleElement : null}

            {!hideCloseButton && (
              <div className="ml-auto self-start">
                <Close />
              </div>
            )}
          </div>
        )}

        <div className={twMerge('overflow-y-auto flex-auto px-7', contentClassName)}>{filteredElements}</div>

        {state.footer ?? null}
      </PrimitiveModal.Content>
    </PrimitiveModal.Portal>
  );
};

Content.displayName = 'MODAL_CONTENT';

export default Content;
