import React from 'react';
import * as PrimitiveModal from '@radix-ui/react-dialog';

type Props = {
  children: React.ReactNode;
} & PrimitiveModal.DialogTriggerProps;

export const Trigger = ({ children, ...props }: Props): JSX.Element => (
  <PrimitiveModal.Trigger {...props}>{children}</PrimitiveModal.Trigger>
);
