import React, { useCallback } from 'react';
import { MarginUtils } from 'utils';
import styled, { css } from 'styled-components';
import Text from './components/text';

export const Types = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  SUCCESS: 'success',
  ERROR: 'error',
  COMPLETE_VOID: 'complete_void',
  ERROR_VOID: 'error_void',
  INACTIVE_VOID: 'inactive_void',
  PENDING: 'pending',
  WARNING: 'warning',
  NORMAL: 'normal',
  SELECTED: 'normal',
  UNSELECTED: 'pending',
  INFO: 'info',
  RED: 'red',
  CARD_INACTVE: 'cardInactive',
  CARD_ACTIVE: 'cardActive',
  CARD_ERROR: 'cardError',
  COMPLETE: 'complete',
  VERIFIED: 'verified',
  INVISIBLE: 'invisible',
  DECLINED: 'declined',
  ACTION_REQUIRED: 'action_required',
  BLUE: 'blue',
  GRAY: 'gray',
  DISCOUNT_CODE: 'discount_code',
  DENIED: 'denied',
} as const;

export const Sizes = {
  HUGE: 'huge',
  BIG: 'big',
  MED: 'med',
  NORMAL: 'normal',
} as const;

const HugeTag = css`
  border-radius: 100px;
  padding: 10px 16px;
`;
const BigTag = css`
  border-radius: 34px;
  padding: 8px 16px;
`;
const MedTag = css`
  border-radius: 34px;
  line-height: 19px;
`;

const getStyleByProps = (props: { size: (typeof Sizes)[keyof typeof Sizes] }) => {
  switch (props.size) {
    case Sizes.BIG:
      return BigTag;

    case Sizes.MED:
      return MedTag;

    case Sizes.HUGE:
      return HugeTag;

    default:
      return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledTag = styled.span<any>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  padding: 4px 8px;
  margin-left: 12px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.08);

  ${getStyleByProps};
  cursor: ${(props) => props.hasClick && 'pointer'};
  margin: ${(props) => props.margin};
  line-height: ${(props) => props.lineHeight};
  border-radius: ${(props) => props.borderRadius};
  font-weight: ${(props) => props.fontWeight};
  background-color: ${(props) => props.backgroundColor};
`;
const SuccessTag = styled(StyledTag)`
  background: var(--positive-green);
  color: var(--green);
`;
const ErrorTag = styled(StyledTag)`
  background: var(--negative-red);
  color: var(--dark-red);
`;
const PendingTag = styled(StyledTag)`
  background: var(--mystic);
  color: var(--grey);
`;
const WarningTag = styled(StyledTag)`
  background: var(--yellow);
  color: var(--yellow-dark);
`;
const InfoTag = styled(StyledTag)`
  background: var(--blue);
  color: var(--blue-dark);
`;
const NormalTag = styled(StyledTag)<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || 'var(--main)'};
  color: var(--white);
`;
const DeniedTag = styled(StyledTag)`
  background: var(--purple-light);
  color: var(--purple);
`;
const RedTag = styled(StyledTag)`
  background: var(--dark-red);
  color: var(--white);
`;
const GrayTag = styled(StyledTag)`
  background: var(--white);
  color: var(--grey);
  border: 1px solid currentColor;
`;
const CardTag = styled(StyledTag)`
  background-color: var(--white);
  border: 1px solid;
  text-transform: capitalize;
`;
const CardErrorTag = styled(CardTag)`
  color: var(--tag-card-error);
`;
const CardActiveTag = styled(CardTag)`
  color: var(--tag-card-active);
`;
const CardInactiveTag = styled(CardTag)`
  color: var(--tag-card-inactive);
`;
const InvisibleTag = styled(CardTag)`
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.21);
  border: none;
`;
const BlueTag = styled(CardTag)<{ selected?: boolean }>`
  background-color: var(--lighter-blue);
  border: 0;

  ${(props) =>
    props.selected &&
    `
  background-color: var(--main);
`}
`;
const DiscountTag = styled(StyledTag)`
  background-color: var(--tag-discount-bg);
  color: var(--black);
  border-radius: 8px;
`;
const ActionRequiredTag = styled(StyledTag)`
  background-color: var(--orange-light);
  color: var(--orange);
`;
type Props = React.PropsWithChildren<{
  value?: string;
  className?: string;
  type?: (typeof Types)[keyof typeof Types];
  size?: (typeof Sizes)[keyof typeof Sizes];
  margin?: (typeof MarginUtils)[keyof typeof MarginUtils];
  lineHeight?: string;
  borderRadius?: string;
  fontWeight?: string;
  bgColor?: string;
  onClick?: (arg0: string | null) => void;
  selected?: boolean;
  dataTestId?: string;
}>;

const Tag = ({
  children,
  value,
  className,
  type,
  margin,
  lineHeight,
  borderRadius,
  fontWeight,
  onClick,
  size,
  bgColor,
  selected,
  dataTestId,
}: Props): JSX.Element => {
  const Component = useCallback(
    (props: Props & { hasClick: boolean; backgroundColor: string | undefined }) => {
      switch (type) {
        case Types.ACTION_REQUIRED:
          return <ActionRequiredTag {...props} />;

        case Types.COMPLETE:
        case Types.SUCCESS:
        case Types.POSITIVE:
          return <SuccessTag {...props} />;

        case Types.ERROR:
        case Types.DECLINED:
        case Types.NEGATIVE:
          return <ErrorTag {...props} />;

        case Types.PENDING:
          return <PendingTag {...props} />;

        case Types.WARNING:
          return <WarningTag {...props} />;

        case Types.INFO:
          return <InfoTag {...props} />;

        case Types.RED:
          return <RedTag {...props} />;

        case Types.VERIFIED:
        case Types.COMPLETE_VOID:
        case Types.CARD_ACTIVE:
          return <CardActiveTag {...props} />;

        case Types.ERROR_VOID:
        case Types.CARD_ERROR:
          return <CardErrorTag {...props} />;

        case Types.INACTIVE_VOID:
        case Types.CARD_INACTVE:
          return <CardInactiveTag {...props} />;

        case Types.INVISIBLE:
          return <InvisibleTag {...props} />;

        case Types.BLUE:
          return <BlueTag {...props} />;

        case Types.DISCOUNT_CODE:
          return <DiscountTag {...props} />;

        case Types.DENIED:
          return <DeniedTag {...props} />;

        case Types.GRAY:
          return <GrayTag {...props} />;

        default:
          return <NormalTag {...props} />;
      }
    },
    [type]
  );

  const handleClick = useCallback(() => {
    onClick?.(value || null);
  }, [onClick, value]);

  return (
    <span className={className}>
      <Component
        data-testid={dataTestId}
        margin={margin}
        onClick={handleClick}
        hasClick={!!onClick}
        size={size}
        lineHeight={lineHeight}
        borderRadius={borderRadius}
        fontWeight={fontWeight}
        backgroundColor={bgColor}
        selected={selected}
      >
        {value ? (
          <Text type="body" className="text-white">
            {value}
          </Text>
        ) : (
          children
        )}
      </Component>
    </span>
  );
};

Tag.Types = Types;
Tag.Sizes = Sizes;

export default Tag;
