import { useState, useCallback } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import DateRangePickerOverlay from './DateRangePickerOverlay';
import DateRangePickerInput from './DateRangePickerInput';
import DateRangePickerContainer from './DateRangePickerContainer';
import DateRangePickerNavbar from './DateRangePickerNavbar';
import * as Popover from '@radix-ui/react-popover';

import type { DayPickerProps } from 'react-day-picker';

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

type Props = {
  onChange: (date?: Date) => void;
  onlyNavbar?: boolean;
  defaultValue?: Date;
  titleFormat?: string;
  emptyTitle?: string;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  className?: string;
  /**
   * @TODO
   *  implement this other way - we're using this component in multiple parts of the app
   *  so the idea would have just a className object maybe and that way we can configure
   *  all the className
   */
  textClassName?: string;
  required?: boolean;
  datePickerProps?: DayPickerProps;
};

const DatePicker = ({
  defaultValue,
  className,
  onChange,
  onlyNavbar = false,
  titleFormat,
  emptyTitle,
  maxDate,
  disabled,
  required,
  datePickerProps,
  ...rest
}: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(defaultValue);
  const [month, setMonth] = useState<Date | undefined>(defaultValue);

  const handleDayClick = useCallback(
    (day: Date, { selected }: DayModifiers) => {
      const newSelectedDay = selected ? undefined : day;
      if (required && !newSelectedDay) return;

      setSelectedDay(newSelectedDay);
      onChange(newSelectedDay);
      setOpen(false);
    },
    [onChange, required]
  );

  const handleYearMonthChange = useCallback((date: Date) => setMonth(date), []);

  return (
    <Popover.Root open={isOpen} onOpenChange={setOpen}>
      <Popover.Trigger disabled={disabled} asChild>
        <DateRangePickerInput
          disabled={disabled}
          titleFormat={titleFormat}
          emptyTitle={emptyTitle}
          from={selectedDay}
          single
          className={className}
          isOpen={isOpen}
          textClassName={rest.textClassName}
        />
      </Popover.Trigger>

      <Popover.Content align="end" sideOffset={8}>
        <DateRangePickerContainer>
          <DateRangePickerOverlay onlyNavbar={onlyNavbar}>
            {onlyNavbar ? (
              <DateRangePickerNavbar
                date={month}
                maxDate={maxDate}
                onChange={(date: Date) => {
                  handleYearMonthChange(date);
                  setSelectedDay(date);
                  onChange(date);
                }}
              />
            ) : (
              <DayPicker
                {...(datePickerProps || {})}
                weekdaysShort={WEEKDAYS_SHORT}
                selectedDays={selectedDay}
                onDayClick={handleDayClick}
                month={month}
                navbarElement={() => null}
                disabledDays={maxDate ? { after: maxDate } : undefined}
                // eslint-disable-next-line react/no-unused-prop-types
                captionElement={({ date }: { date: Date }): JSX.Element => (
                  <DateRangePickerNavbar date={date} maxDate={maxDate} onChange={handleYearMonthChange} />
                )}
              />
            )}
          </DateRangePickerOverlay>
        </DateRangePickerContainer>
      </Popover.Content>
    </Popover.Root>
  );
};

export default DatePicker;
