import { getBenefitsAPI } from './utils';
import { opsConfig, opsdashFeatures } from './opsdash';
import { claimsConfig, claimsFeatures } from './claims';
import { adminConfig, adminFeatures } from './admindash';

const isOpsDash: boolean = process.env.REACT_APP_OPS_DASHBOARD === 'true' || false;
const isClaims: boolean = process.env.REACT_APP_CLAIMS_DASHBOARD === 'true' || false;
const track: boolean = process.env.REACT_APP_TRACK !== 'false';

const { config: currentConfig, features } = (() => {
  if (isClaims) return { config: claimsConfig, features: claimsFeatures };
  if (isOpsDash) return { config: opsConfig, features: opsdashFeatures };
  return { config: adminConfig, features: adminFeatures };
})();

const common = {
  api: {
    origin: process.env.REACT_APP_BENEFITS_API || 'https://api.benefitsapi.com/',
    graphql: process.env.REACT_APP_GRAPHQL || getBenefitsAPI() || 'https://api.benefitsapi.com/graphql',
    beef: process.env.REACT_APP_BEEF || getBenefitsAPI() || `https://api.benefitsapi.com/beef/`,
  },
  sentry: {
    dsn: currentConfig.sentryDsn,
    environment: 'production',
  },
  plaid: { environment: 'production', origin: 'https://link.getbenepass.com' },
  track,
};

type Config = {
  isClaims: boolean;
  isOpsDash: boolean;
  api: {
    origin: string;
    graphql: string;
    beef: string;
  };
  sentry: {
    dsn: string;
    environment: string;
  };
  plaid: {
    environment: string;
    origin: string;
  };
  track: boolean;
  features: { [key: string]: boolean };
};

const config: Config = {
  isClaims,
  isOpsDash,
  ...common,
  ...currentConfig,
  features: { ...features },
};

export default config;
