import React, { useEffect } from 'react';
import MinScreenProvider from 'utils/src/hooks/use-min-screen';
import * as Sentry from '@sentry/react';

import { BrowserRouter as Router } from 'react-router-dom';
import { OverlayLoader, PageError, Message, SuspenseWrapper } from 'react-components';
import { createEnvironment } from 'utils/src/RelayEnvironment';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { theme } from 'twin.macro';
import { LogCategories, errorHandler } from 'utils/src/Logs';
import { contactSupportMessage } from 'utils/src/Support';

import current from '@config';
// @ts-expect-error javascript module
import Authenticator from './components/Authenticator';
import Routes from '../pages/routes';

import projectPackage from '../../package.json';
import CompanyPickerProvider, { useCompanyPicker } from '../hooks/use-company-picker';
import { useAuthentication } from '../data/auth/transition';
import { ToastProvider } from 'react-components/src/components/toast';
import { DialogProvider } from 'react-components/src/components/dialog';

const { MessageTypes } = Message;
const { version } = projectPackage;

const appHeader = (() => {
  if (current.isClaims) return 'claims';
  if (current.isOpsDash) return 'ops';

  return 'admin';
})();

const Main = () => {
  const { user, getAccessToken } = useAuthentication();
  const [employerId] = useCompanyPicker();

  const relayEnvironment = React.useMemo(
    () =>
      createEnvironment({
        graphQLUrl: current.api.graphql,
        getAccessTokenSilently: getAccessToken,
        email: user?.email,
        extraHeaders: {
          'x-benepass-platform': 'web',
          'x-benepass-client': appHeader,
          'x-benepass-version': process.env.npm_package_version || version,
          ...(employerId ? { 'x-benepass-employerId': employerId } : {}),
        },
      }),
    [employerId, getAccessToken, user?.email]
  );

  useEffect(() => {
    Sentry.setUser(user);
  }, [user]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <SuspenseWrapper
        loader={<OverlayLoader message="Loading your data..." />}
        errorFallback={
          <PageError title="Server Unreachable">
            <Message type={MessageTypes.ERROR}>Something is wrong on Benepass Servers. {contactSupportMessage}</Message>
          </PageError>
        }
        onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
      >
        <Routes />
        <ToastProvider />
        <DialogProvider />
      </SuspenseWrapper>
    </RelayEnvironmentProvider>
  );
};

const App = () => (
  <Authenticator>
    <MinScreenProvider screens={theme`screens`}>
      <Router>
        <CompanyPickerProvider>
          <Main />
        </CompanyPickerProvider>
      </Router>
    </MinScreenProvider>
  </Authenticator>
);

export default App;
