import React, { useState, useCallback } from 'react';
import tw, { TwStyle } from 'twin.macro';
import styled, { css } from 'styled-components';
import clsx from 'clsx';
import styles from './Card.module.css';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import CardBody from './CardBody';
import CardGroup from './CardGroup';

import { Close as CloseIcon } from '@benepass/icons';

const Margins = {
  SMALL: 'small',
  NORMAL: 'normal',
  NONE: 'none',
};
const Vertical = {
  NORMAL: 'unset',
  CENTER: 'center',
};
const Padding = {
  NONE: tw`p-0`,
  SMALL: tw`py-5 px-4`,
  MEDIUM: tw`py-5 px-5`,
  NORMAL: tw`py-7 px-6`,
  LARGE: tw`p-8`,
};
const StyledGrid = css`
  display: grid;
  grid-column-gap: 16px;
`;
const StyledCard = styled.article<Props & { gridTemplateColumns?: string; alignItems?: string }>`
  position: relative;
  border: ${(props) => (props.border || props.shadow ? '1px solid var(--mystic)' : 'none')};
  box-shadow: ${(props) => props.shadow && '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(0, 0, 0, 0.05)'};
  border-radius: ${(props) => props.borderRadius || '8px'};
  width: 100%;
  background-color: ${(props) => props.backgroundColor || 'var(--white)'};
  color: ${(props) => props.color || 'var(--dark)'};
  margin: ${(props) => props.margin};
  ${(props) => props.gridTemplateColumns && StyledGrid}
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  align-items: ${(props) => props.alignItems};
  cursor: ${(props) => props.onClick && 'pointer'};
  width: ${(props) => props.width};
  ${(props) => props.padding}
`;

type Props = React.PropsWithChildren<{
  closeable?: boolean;
  defaultVisible?: boolean;
  onClose?: (event: React.MouseEvent<HTMLDivElement> | null | undefined) => void;
  columns?: string;
  padding?: TwStyle;
  margin?: (typeof Margins)[keyof typeof Margins];
  verticalAlignment?: (typeof Vertical)[keyof typeof Vertical];
  className?: string;
  shadow?: boolean;
  border?: string;
  backgroundColor?: string;
  color?: string;
  width?: string;
  onClick?: () => void;
  borderRadius?: string;
  dataTestId?: string;
}>;

const Card = ({
  children,
  defaultVisible = true,
  onClose,
  closeable = false,
  columns,
  padding = Padding.NORMAL,
  margin,
  width,
  verticalAlignment,
  className,
  shadow = false,
  backgroundColor,
  onClick,
  color,
  border,
  borderRadius,
  dataTestId,
}: Props): JSX.Element | null => {
  const [visible, setVisible] = useState(defaultVisible);
  const handleCloseClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setVisible(false);
      onClose?.(event);
    },
    [setVisible, onClose]
  );
  const CloseButton = useCallback(() => {
    if (!closeable) {
      return null;
    }

    return (
      <div role="button" className={styles.closeButton} onClick={handleCloseClick} aria-label="Close button">
        <CloseIcon />
      </div>
    );
  }, [closeable, handleCloseClick]);

  if (!visible) {
    return null;
  }

  return (
    <StyledCard
      data-testid={dataTestId}
      border={border}
      margin={margin}
      gridTemplateColumns={columns}
      alignItems={verticalAlignment}
      shadow={shadow}
      backgroundColor={backgroundColor}
      padding={padding}
      className={clsx('benepass_card', styles.card, className)}
      onClick={onClick}
      borderRadius={borderRadius}
      color={color}
      width={width}
    >
      <CloseButton />
      {children}
    </StyledCard>
  );
};

Card.CardHeader = CardHeader;
Card.CardFooter = CardFooter;
Card.CardBody = CardBody;
Card.CardGroup = CardGroup;
Card.Vertical = Vertical;
Card.Margins = Margins;
Card.Padding = Padding;

export { CardHeader, CardFooter, Margins, CardBody, Vertical, CardGroup };
export default Card;
