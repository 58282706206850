import React from 'react';
import { classNames as cn } from 'utils/src/classnames';
import * as Primitive from '@radix-ui/react-dropdown-menu';
import Text from '../text';
import customTwMerge from '../../utils/twMerge';

type Props = React.PropsWithChildren<Primitive.DropdownMenuProps>;

export const Root = ({ children, ...props }: Props): JSX.Element => (
  <Primitive.Root {...props}>{children}</Primitive.Root>
);

export const Content = ({
  children,
  className = '',
  style,
}: Props & {
  className?: string;
  style?: React.CSSProperties;
}): JSX.Element => (
  <Primitive.Content
    sideOffset={12}
    className={customTwMerge(cn(['bg-white shadow-3dp overflow-y-scroll rounded-lg min-w-[220px]', className ?? '']))}
    style={{
      maxHeight: 400,
      ...(style ?? {}),
    }}
  >
    {children}
  </Primitive.Content>
);

Content.displayName = 'DROPDOWN_CONTENT';

export const Item = ({
  children,
  className,
  onSelect,
  ...props
}: Props & {
  className?: string;
  onSelect?: (event: Event) => unknown;
}): JSX.Element => (
  <Primitive.Item
    {...props}
    onSelect={onSelect ?? undefined}
    className={customTwMerge(
      cn([
        'px-6 py-3 outline-none hover:bg-gray-100 focus:bg-gray-100',
        {
          'cursor-pointer': Boolean(onSelect),
        },
      ]),
      className ?? ''
    )}
  >
    {children}
  </Primitive.Item>
);

export const Label = ({ children, className, ...props }: { children: string; className?: string }): JSX.Element => (
  <Primitive.Label {...props} className={cn(['px-6 py-1', className ?? ''])}>
    <Text className="text-grayscale-48">{children}</Text>
  </Primitive.Label>
);

export const Separator = ({ className }: { className?: string }): JSX.Element => (
  <Primitive.Separator>
    <hr className={cn(['border-1 border-grayscale-border py-1 self-center', className ?? ''])} />
  </Primitive.Separator>
);

export const { Group } = Primitive;
export * from './trigger';
