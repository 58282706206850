import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Loading from './Loading';

const StyledOverlay = styled.div`
  ${tw`fixed inset-0 z-50 bg-white bg-opacity-90`}
  ${tw`flex items-center justify-center flex-col`}
  transform: translateZ(0px);
`;

const OverlayLoaderSmall = () => (
  <StyledOverlay>
    <Loading />
  </StyledOverlay>
);

export default OverlayLoaderSmall;
