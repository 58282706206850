/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-expect-error flow
import * as cognitoAuth from './Auth';

export const {
  getAccessTokenSync,
  useAuthentication,
  AuthenticationProvider,
  AuthenticationContext,
}: { [key: string]: any } = cognitoAuth;
