import React from 'react';
import useLocalStorage from 'utils/src/hooks/useLocalStorage';
import useRouteState from 'utils/src/hooks/useRouteState';
import config from '@config';

import { getFirstPath } from 'utils/src/url-utils';
import { useHistory } from 'react-router-dom';

type Value = [
  string | undefined,
  (
    | ((
        value: string,
        options?: { source?: 'localstorage' | 'route'; callback?: () => Promise<unknown> }
      ) => unknown | Promise<unknown>)
    | undefined
  ),
  /**
   * Callbacks to be executed when the employerId changes.
   */
  {
    add: (id: string, callback: () => unknown) => void;
    remove: (id: string) => void;
  },
];

const Context = React.createContext<Value | undefined>(undefined);
const localStorageKey = config.isOpsDash ? 'ops-dashboard-employer-id' : 'admin-dashboard-employer-id';

export const useCompanyPicker = (): Value => {
  const context = React.useContext<Value>(Context as unknown as React.Context<Value>);

  if (context === undefined) {
    throw new Error('`useCompanyPicker` must be used within a `CompanyPickerProvider`');
  }

  return context;
};

const Provider = ({ children }: React.PropsWithChildren<unknown>) => {
  const history = useHistory();

  const [{ employerId }, setRouteState] = useRouteState<{ employerId?: string }>({ employerId: '' });
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey, '');

  const callbacks = React.useMemo(() => new Map<string, () => unknown>(), []);

  const handleLocalStorageChange = React.useCallback(
    async (value: string, options?: { callback?: () => Promise<unknown> }) => {
      setLocalStorageValue(value);

      if (options?.callback) {
        await options.callback();
      }

      const values = Array.from(callbacks.values());
      await Promise.all(values.map((callback) => callback()));
    },
    [setLocalStorageValue, callbacks]
  );

  React.useEffect(() => {
    if (employerId && (!localStorageValue || employerId !== localStorageValue)) {
      handleLocalStorageChange(employerId);
    }
  }, [employerId, localStorageValue, handleLocalStorageChange]);

  React.useEffect(() => {
    if (!employerId && localStorageValue) {
      setRouteState({ employerId: localStorageValue });
    }
  }, [employerId, localStorageValue, setRouteState]);

  React.useEffect(() => {
    const navigationCallbackId = 'root:navigation';

    if (!callbacks.get(navigationCallbackId)) {
      callbacks.set(navigationCallbackId, () => history.replace(`${getFirstPath(history.location.pathname)}`));
    }

    return () => {
      callbacks.delete(navigationCallbackId);
    };
  }, [history, callbacks]);

  return (
    <Context.Provider
      value={[
        localStorageValue,
        (value, options) => {
          if (options?.source === 'localstorage') {
            handleLocalStorageChange(value, { callback: options.callback });
          } else {
            setRouteState({ employerId: value });
          }
        },
        {
          add: (id, callback) => callbacks.set(id, callback),
          remove: (id) => callbacks.delete(id),
        },
      ]}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
