import React from 'react';
import clsx from 'clsx';
import styles from './Card.module.css';

type Props = React.PropsWithChildren<{
  columns?: string;
  border?: boolean;
  margin?: string;
  className?: string;
}>;

function CardBody({ children, className, border = true, columns = '1fr 1fr', margin }: Props): JSX.Element {
  /* TODO: Extract this className logic to a function https://github.com/benepass/webapp/pull/33/files#r520669641 */
  return (
    <div
      className={clsx(styles.body, className, {
        [styles.border as string]: border,
      })}
      style={{
        gridTemplateColumns: columns,
        margin,
      }}
    >
      {children}
    </div>
  );
}

export default CardBody;
