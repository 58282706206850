import React from 'react';
import styled from 'styled-components';
import { classNames } from 'utils/src/classnames';
import twMerge from '../../utils/twMerge';
import { useTextClassNames } from '../text/styles';

const StyledAnchor = styled.a``;
type Size = 'sm' | 'md' | 'lg';

export type AnchorProps = React.HTMLAttributes<HTMLAnchorElement> & {
  href?: string;
  size?: Size;
  shouldOpenNewTab?: boolean;
  className?: string;
};

const Link = ({ size = 'lg', shouldOpenNewTab = false, className, ...props }: AnchorProps): JSX.Element => {
  const baseText = useTextClassNames({ ...props, type: 'body', size });
  const classes = classNames([
    baseText,
    'text-pink-100 hover:text-pink-200 focus:text-red-200 disabled:text-grayscale-48',
    'p-0 cursor-pointer inline-flex font-medium',
    className ?? '',
  ]);
  const baseProps = shouldOpenNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return <StyledAnchor {...props} {...baseProps} className={twMerge(classes)} />;
};

export default Link;
