import moment from 'moment';
import type { Moment } from 'moment';

export const fromFormat = (date: string | number, format: string): Moment => {
  return moment(date, format).utc();
};

export const fromUnixTime = (timestamp: string | number): Moment => {
  return fromFormat(timestamp, 'X');
};

export const fromDate = (date?: Date | string): Moment => {
  if (!date) {
    return moment().utc();
  }
  return moment(date).utc();
};

export const fromDateLocal = (date?: Date | string): Moment => {
  if (!date) {
    return moment();
  }
  return moment(date);
};

export const toAPIDate = (date: Moment): string => {
  return date.clone().format('YYYY-MM-DD');
};

export const toAPIDateJS = (date: Date): string => toAPIDate(fromDateLocal(date));

// react-day-picker uses dates in local timezone, so we need to convert
// utc into local timezone without changing the actual time since otherwise
// it changes the date when it crosses midnight
export const toLocalTimeNoZone = (date?: Moment): Date | undefined =>
  date ? new Date(date.format('YYYY-MM-DDTHH:mm:ss.SSS')) : undefined;
