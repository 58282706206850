import { createTailwindMerge, getDefaultConfig } from 'tailwind-merge';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore untyped config
import tailwindConfig from '@benepass/tailwind-config-web';

const customTwMerge = createTailwindMerge(() => {
  const defaultConfig = getDefaultConfig();
  const fontSizes = Object.keys(tailwindConfig?.theme?.extend?.fontSize || {});
  return {
    ...defaultConfig,
    classGroups: {
      ...defaultConfig.classGroups,
      'font-size': [
        {
          text: [...fontSizes, ...defaultConfig.classGroups['font-size'][0].text],
        },
      ],
    },
  };
});

export default customTwMerge;
