import { classNames } from 'utils/src/classnames';
import twMerge from '../../utils/twMerge';

import type { Props as ButtonProps } from '.';

const primary = classNames([
  'bg-indigo-100 hover:bg-opacity-80 focus:bg-indigo-200 disabled:bg-indigo-20',
  'text-white',
]);
const secondary = classNames([
  'bg-white hover:bg-grayscale-2 focus:bg-indigo-10 disabled:bg-white',
  'border border-indigo-100 disabled:border-indigo-20',
  'text-indigo-100 disabled:text-indigo-40',
]);
const ghost = classNames([
  'bg-white hover:bg-indigo-10 focus:bg-indigo-20',
  'text-indigo-100 disabled:text-grayscale-48',
]);
const link = classNames([
  'text-purple-100 hover:text-pink-200 focus:text-red-200 disabled:text-grayscale-48 font-medium p-0',
]);
const float = classNames([
  'text-center justify-center items-center',
  'p-3.5 w-12 h-12',
  'shadow-2dp hover:shadow-3dp active:shadow-2dp',
  'bg-pink-100 focus:bg-pink-200 disabled:bg-opacity-50',
  'text-white',
  'rounded-full',
]);

const sm = classNames(['px-4 py-2']);
const md = classNames(['px-6 py-3']);
const lg = classNames(['px-8 py-4', 'text-lg']);
const base = classNames([
  'disabled:cursor-not-allowed',
  'flex items-center justify-center',
  'focus:outline-none',
  'rounded-lg',
  'text-base',
]);

export const useButtonClassNames = (props: ButtonProps): string => {
  const variantClasses = {
    primary,
    secondary,
    ghost,
    link,
    float,
  };

  const sizeClasses = {
    sm,
    md,
    lg,
  };

  const classes = classNames([
    base,
    sizeClasses[(props.size as keyof typeof sizeClasses) || 'lg'],
    variantClasses[props.variant as keyof typeof variantClasses],
    props.className ?? '',
  ]);

  return twMerge(classes);
};
