import React from 'react';
import styled from 'styled-components';
import { LayoutUtils, MarginUtils } from 'utils';

const { AlignItems } = LayoutUtils;
export { AlignItems };
export const JustifyContent = AlignItems;
export const Wrap = {
  NOWRAP: 'nowrap',
  WRAP: 'wrap',
  REVERSE: 'wrap-reverse',
};
export const FlexFlow = {
  COLUMN: 'column',
  ROW: 'row',
};
const Component = styled.div<Props>`
  display: flex;
  flex-flow: ${(props) => props.flow};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  flex-wrap: ${(props) => props.wrap};
  flex-grow: ${(props) => props.grow};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
`;

type Props = React.PropsWithChildren<{
  alignItems?: (typeof AlignItems)[keyof typeof AlignItems];
  justifyContent?: (typeof JustifyContent)[keyof typeof JustifyContent];
  margin?: (typeof MarginUtils)[keyof typeof MarginUtils];
  wrap?: (typeof Wrap)[keyof typeof Wrap];
  flow?: (typeof FlexFlow)[keyof typeof FlexFlow];
  className?: string;
  style?: React.CSSProperties;
  grow?: number;
  padding?: string;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  dataTestId?: string;
}>;

const Flex = ({
  children,
  flow,
  alignItems,
  justifyContent,
  margin,
  wrap,
  grow,
  className,
  padding,
  style = {},
  color,
  onClick,
  dataTestId,
}: Props): JSX.Element => {
  return (
    <Component
      style={style}
      color={color}
      flow={flow}
      className={className}
      alignItems={alignItems}
      justifyContent={justifyContent}
      wrap={wrap}
      margin={margin}
      grow={grow}
      padding={padding}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </Component>
  );
};

Flex.AlignItems = AlignItems;
Flex.JustifyContent = JustifyContent;
Flex.Wrap = Wrap;
Flex.FlexFlow = FlexFlow;

export default Flex;
