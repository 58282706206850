import React from 'react';

export type AnalyticsContextValue = {
  page: (path: string, params?: Record<string, unknown>) => void;
  identify: (id?: string, params?: Record<string, unknown>) => void;
  track: (event: string, params?: Record<string, unknown>) => void;
};

const AnalyticsContext = React.createContext<AnalyticsContextValue | null>(null);

export default AnalyticsContext;
