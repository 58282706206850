export const Columns = {
  '24_AUTO': '24px auto',
  '32_AUTO': '32px auto',
  SINGLE_COLUMN: '1fr',
  DOUBLE_COLUMN: '1fr 1fr',
  TRIPLE_COLUMN: 'minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) ',
  SMALL_BIG: '1fr 2fr',
  HEADER_FILTER: '1fr auto',
  HEADER_DOUBLE_FILTER: '1fr auto auto',
};

export const AlignItems = {
  BASELINE: 'baseline',
  CENTER: 'center',
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  START: 'start',
  END: 'end',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  SPACE_EVENLY: 'space-evenly',
};
