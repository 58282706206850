import React from 'react';
import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import twMerge from '../../utils/twMerge';

import { classNames } from 'utils/src/classnames';

import type { PrimitiveDivProps } from '@radix-ui/react-accordion';

export type Props = PrimitiveDivProps & {
  children: React.ReactNode;
  value: string;
  disabled?: boolean;
  className?: string;
};

export const Item = ({ className, children, value, disabled = false, ...props }: Props): JSX.Element => {
  const classes = classNames(['border border-grayscale-2 rounded-lg bg-white', className ?? '']);

  return (
    <PrimitiveAccordion.Item {...props} disabled={disabled} value={value} className={twMerge(classes)}>
      {children}
    </PrimitiveAccordion.Item>
  );
};
