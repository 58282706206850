import { classNames } from 'utils/src/classnames';

import twMerge from '../../utils/twMerge';

type Props = {
  children: JSX.Element;
  className?: string;
};

const Footer = ({ children, className }: Props): JSX.Element => {
  const classes = classNames([className ?? '']);
  const style = twMerge(classes);
  return <div className={style}>{children}</div>;
};

Footer.displayName = 'MODAL_FOOTER';

export default Footer;
