export const getBenefitsAPI = (): string | null => {
  const benefitsAPI = process.env.REACT_APP_BENEFITS_API;
  if (!benefitsAPI) {
    return null;
  }
  if (benefitsAPI.endsWith('/')) {
    return benefitsAPI;
  }
  return `${benefitsAPI}/`;
};
