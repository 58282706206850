import moment from 'moment';

import { createArrayCsvStringifier } from 'csv-writer';

import { downloadUrl } from './core-utils';

export type ItemType = string | number;

export const exportCSVFile = (headers: Array<ItemType>, items: Array<Array<ItemType>>, fileTitle = 'export'): void => {
  const csvStringifier = createArrayCsvStringifier({
    header: headers.map((h) => h.toString()),
  });
  const stringifiedHeaders = csvStringifier.getHeaderString() as string;
  const stringifiedRecords = csvStringifier.stringifyRecords(items);

  const readableNow = moment().format('yyyy-M-dd_h.mma');
  const exportedFilename = `benepass_${fileTitle}_${readableNow}.csv`;

  const blob = new Blob([stringifiedHeaders, stringifiedRecords], {
    type: 'text/csv;charset=utf-8;',
  });

  const url = URL.createObjectURL(blob);
  downloadUrl(url, exportedFilename);
};
