const SentryCategories = {
  ACCOUNT: 'Account Page',
  ADDRESSES: 'Address Handling',
  AUTH: 'Authorization',
  EXPLORE: 'Explore Page',
  HOME: 'Home Page',
  LOADING: 'LOADING',
  PUSH_NOTIFICATIONS: 'Push Notifications',
  REIMBURSEMENTS: 'Reimbursement Form',
  TRANSACTION_UPDATE: 'Transaction Update',
  TRANSACTION_SAVE: 'Transaction Mutations',
  UPLOADS: 'Uploads',
  WALLET: 'Wallet (Push Provisioning)',
  OVERAGES: 'User personal overages',
  REPORTS: 'Reports',
  ROSTER: 'Roster',
  PLATFORM_FUNDING: 'Platform Funding',
  VGS: 'VGS',
  FORM_BUILDER: 'Form Builder',
  UI_BUILDER: 'UI Builder',
  GRAPHQL_APOLLO: 'GraphQL Apollo',
  INTEGRATIONS: 'Integrations',
  WARNING: 'Warning',
  MANUAL_CONTRIBUTION: 'Manual contribution',
  CONSEQUENCE_STATE: 'Consequence state',
  FORM_SUBMISSION: 'Form Submission',

  // Uncategorized TODO: make this specific
  UNKNOWN_MUTATION: 'Unknown Mutation',
  UNKNOWN_ERROR: 'Unknown Error',
  UNKNOWN_LOCAL_STORAGE: 'Unknown LocalStorage',
  UNKNOWN_GRAPHQL: 'Unknown GraphQL',
} as const;

type $Values<O> = O[keyof O];

export type SentryCategory = $Values<typeof SentryCategories>;

export default SentryCategories;
