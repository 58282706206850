/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
/**
 * @disclaimer
 * this code is from @radix-ui's website. They have an internal component that
 * will release in a bit, but for now we can use the one they use internally.
 * Once they deploy the carousel component we'll need to remove these dependencies:
 * - @radix-ui/react-compose-refs
 * - @radix-ui/react-context
 * - @radix-ui/react-use-callback-ref
 * - @radix-ui/primitive
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { createContext } from '@radix-ui/react-context';
import { useCallbackRef } from '@radix-ui/react-use-callback-ref';
import { composeEventHandlers } from '@radix-ui/primitive';
import { classNames } from 'utils/src/classnames';
import { debounce } from 'utils/src/core-utils';
import twMerge from '../../utils/twMerge';
import styles from './carousel.module.css';

const [CarouselProvider, useCarouselContext] = createContext<{
  _: any;
  slideListRef: any;
  onNextClick(): void;
  onPrevClick(): void;
  nextDisabled: boolean;
  prevDisabled: boolean;
}>('Carousel');

export const Carousel = (props: any): JSX.Element => {
  const ref = useRef<any>(null);
  const { children, ...carouselProps } = props;
  const slideListRef = useRef<any>(null);
  const [_, force] = useState({});
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const timeoutRef = useRef<any>();
  const navigationUpdateDelay = useRef(100);

  const getSlideInDirection = useCallbackRef((direction: 1 | -1) => {
    const slides = ref.current?.querySelectorAll('[data-slide-intersection-ratio]');

    if (slides) {
      const slidesArray = Array.from(slides.values());

      if (direction === 1) {
        slidesArray.reverse();
      }

      return slidesArray.find((slide: any) => slide.dataset.slideIntersectionRatio !== '0');
    }

    return null;
  });

  const handleNextClick = useCallback(() => {
    const nextSlide = getSlideInDirection(1) as any;

    if (nextSlide) {
      const { scrollLeft, scrollWidth, clientWidth } = slideListRef.current;
      const itemWidth = nextSlide.clientWidth;
      const itemsToScroll =
        itemWidth * 2.5 < (ref.current?.offsetWidth || document.documentElement?.offsetWidth || 0) ? 2 : 1;
      const nextPos = Math.round(scrollLeft / itemWidth) * itemWidth + itemWidth * itemsToScroll;
      slideListRef.current.scrollTo({
        left: nextPos,
        behavior: 'smooth',
      });
      // Disable previous & next buttons immediately
      setPrevDisabled(nextPos <= 0);
      setNextDisabled(scrollWidth - nextPos - clientWidth <= 0);
      // Wait for scroll animation to finish before the buttons *might* show up again
      navigationUpdateDelay.current = 500;
    }
  }, [getSlideInDirection, setPrevDisabled]);

  const handlePrevClick = useCallback(() => {
    const prevSlide = getSlideInDirection(-1) as any;

    if (prevSlide) {
      const { scrollLeft, scrollWidth, clientWidth } = slideListRef.current;
      const itemWidth = prevSlide.clientWidth;
      const itemsToScroll =
        itemWidth * 2.5 < (ref.current?.offsetWidth || document.documentElement?.offsetWidth || 0) ? 2 : 1;
      const nextPos = Math.round(scrollLeft / itemWidth) * itemWidth - itemWidth * itemsToScroll;
      slideListRef.current.scrollTo({
        left: nextPos,
        behavior: 'smooth',
      });
      // Disable previous & next buttons immediately
      setPrevDisabled(nextPos <= 0);
      setNextDisabled(scrollWidth - nextPos - clientWidth <= 0);
      // Wait for scroll animation to finish before the buttons *might* show up again
      navigationUpdateDelay.current = 500;
    }
  }, [getSlideInDirection, setPrevDisabled]);

  useEffect(() => {
    // Keep checking for whether we need to disable the navigation buttons, debounced
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      requestAnimationFrame(() => {
        if (slideListRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = slideListRef.current;
          setPrevDisabled(scrollLeft <= 0);

          const carouselNextWrapper = ref.current?.querySelector('#carousel-next-wrapper') as HTMLElement | null;
          const carouselNextWidth = carouselNextWrapper?.parentElement?.offsetWidth || 50;

          setNextDisabled(scrollWidth - scrollLeft - clientWidth - carouselNextWidth <= 0);
          navigationUpdateDelay.current = 100;
        }
      });
    }, navigationUpdateDelay.current);
  });

  useEffect(() => {
    const slidesList = slideListRef.current;

    if (slidesList) {
      const handleScrollStartAndEnd = debounce(() => force({}), 100, {
        leading: true,
        trailing: true,
      });
      slidesList.addEventListener('scroll', handleScrollStartAndEnd);
      window.addEventListener('resize', handleScrollStartAndEnd);
      force({});
      return () => {
        slidesList.removeEventListener('scroll', handleScrollStartAndEnd);
        window.removeEventListener('resize', handleScrollStartAndEnd);
      };
    }

    return undefined;
  }, [slideListRef]);

  return (
    <CarouselProvider
      _={_}
      nextDisabled={nextDisabled}
      prevDisabled={prevDisabled}
      slideListRef={slideListRef}
      onNextClick={handleNextClick}
      onPrevClick={handlePrevClick}
    >
      <div {...carouselProps} ref={ref}>
        {children}
      </div>
    </CarouselProvider>
  );
};

export const CarouselSlideList = (props: any): JSX.Element => {
  const context = useCarouselContext('CarouselSlideList');
  const ref = useRef<any>(null);
  const composedRefs = useComposedRefs(ref, context.slideListRef);
  const [dragStart, setDragStart] = useState<any>(null);
  const [canScroll, setCanScroll] = useState<boolean>(true);

  const handleMouseMove = useCallbackRef((event) => {
    if (ref.current) {
      const distanceX = event.clientX - dragStart.pointerX;
      ref.current.scrollLeft = dragStart.scrollX - distanceX;
    }
  });

  const handleMouseUp = useCallbackRef(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    setDragStart(null);
  });

  useEffect(() => {
    const updateCanScroll = () => {
      const currentReference = context.slideListRef?.current;
      const updatedCanScroll = currentReference?.scrollWidth > currentReference?.parentElement?.clientWidth;

      if (canScroll !== updatedCanScroll) setCanScroll(updatedCanScroll);
    };

    updateCanScroll();
    window.addEventListener('resize', updateCanScroll);

    return () => window.removeEventListener('resize', updateCanScroll);
  }, [canScroll, context.slideListRef]);

  // @notes
  // on children change it should scroll back to the initial state. This was because we were
  // updating children list (merchants carousel) but we were using "the same" <Carousel> instance
  // so only changing the childrenkept the scroll position.
  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [props?.children]);

  return (
    <div
      {...props}
      className={twMerge(
        classNames([
          styles['without-scrollbar'],
          'grid grid-flow-col overflow-x-scroll gap-4',
          canScroll ? '' : 'w-min',
          props.className || '',
        ])
      )}
      ref={composedRefs}
      data-state={dragStart ? 'dragging' : undefined}
      onMouseDownCapture={composeEventHandlers(props.onMouseDownCapture, (event: MouseEvent) => {
        // Drag only if main mouse button was clicked
        if (event.button === 0) {
          document.addEventListener('mousemove', handleMouseMove);
          document.addEventListener('mouseup', handleMouseUp);
          setDragStart({
            scrollX: (event.currentTarget as any)?.scrollLeft,
            pointerX: event.clientX,
          });
        }
      })}
      onPointerDown={composeEventHandlers(props.onPointerDown, (event: PointerEvent) => {
        const element: any = event.target;
        element.style.userSelect = 'none';
        element.setPointerCapture(event.pointerId);
      })}
      onPointerUp={composeEventHandlers(props.onPointerUp, (event: PointerEvent) => {
        const element: any = event.target;
        element.style.userSelect = '';
        element.releasePointerCapture(event.pointerId);
      })}
    />
  );
};

export const CarouselSlide = (props: any): JSX.Element => {
  const { as: Comp = 'div', ...slideProps } = props;
  const context = useCarouselContext('CarouselSlide');
  const ref = useRef<any>(null);
  const [intersectionRatio, setIntersectionRatio] = useState(0);
  const isDraggingRef = useRef(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersectionRatio((entry as any).intersectionRatio), {
      root: context.slideListRef.current,
      threshold: [0, 0.5, 1],
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [context.slideListRef]);
  return (
    <Comp
      {...slideProps}
      ref={ref}
      data-slide-intersection-ratio={intersectionRatio}
      onDragStart={(event: any) => {
        event.preventDefault();
        isDraggingRef.current = true;
      }}
      role="presentation"
      onClick={(event: any) => {
        if (isDraggingRef.current) {
          event.preventDefault();
        }
      }}
    />
  );
};

export const CarouselNext = (props: any): JSX.Element => {
  const { as: Comp = 'button', ...nextProps } = props;
  const context = useCarouselContext('CarouselNext');
  return (
    <Comp
      {...nextProps}
      onClick={() => context.onNextClick()}
      id="carousel-next-wrapper"
      disabled={context.nextDisabled}
    />
  );
};

export const CarouselPrevious = (props: any): JSX.Element => {
  const { as: Comp = 'button', ...prevProps } = props;
  const context = useCarouselContext('CarouselPrevious');
  return (
    <Comp
      {...prevProps}
      onClick={() => context.onPrevClick()}
      id="carousel-previous-wrapper"
      disabled={context.prevDisabled}
    />
  );
};

export const CarouselArrowWrapper = ({
  children,
  direction,
}: React.PropsWithChildren<{ direction: 'next' | 'previous' }>) => {
  const previousContext = useCarouselContext('CarouselPrevious');
  const nextContext = useCarouselContext('CarouselNext');

  const shouldNotShow =
    (previousContext.prevDisabled && direction === 'previous') || (nextContext.nextDisabled && direction === 'next');
  return <div>{shouldNotShow ? null : children}</div>;
};
