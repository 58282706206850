import { useState, useEffect } from 'react';

import current from '../config';

import { useAuthentication } from '../data/auth/transition';
import { authenticatedFetch } from './fetch';

const Status = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};

const CHECK_ENDPOINT = (() => {
  if (current.isClaims) return 'claims_check';
  if (current.isOpsDash) return 'ops_check';

  return 'admin_check';
})();

const useAdminCheck = (): { status: string } => {
  const { getAccessToken } = useAuthentication();
  const [status, setStatus] = useState(Status.loading);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const token = await getAccessToken({});
      const response = await authenticatedFetch(token, `${current.api.origin}${CHECK_ENDPOINT}/`);

      if (response.ok) {
        setStatus(Status.success);
      } else {
        setStatus(Status.error);
      }
    }

    fetchData();
  }, [getAccessToken]);

  return {
    status,
  };
};

export { Status, useAdminCheck };
