import React from 'react';
import { AngleDown } from '@benepass/icons';

import * as Dropdown from '../dropdown';
import Text from '../text';
import customTwMerge from '../../utils/twMerge';

type Currency = {
  apiID: string;
  isZeroDecimal: boolean;
  readableSymbol: string;
};

type Props = {
  currencies: Currency[];
  selectedCurrency: string;
  setFieldValue: (field: string, value: string) => unknown;
  className?: {
    trigger?: React.ComponentProps<typeof Dropdown.Trigger>['className'];
    content?: React.ComponentProps<typeof Dropdown.Content>['className'];
  };
};

const CurrencySelect = ({ currencies, selectedCurrency, setFieldValue, ...props }: Props) => {
  const currentCurrencySymbol = React.useMemo(() => {
    return currencies.find((currency) => currency.apiID === selectedCurrency)?.readableSymbol;
  }, [currencies, selectedCurrency]);

  return (
    <Dropdown.Root data-value={selectedCurrency} data-testid="currency-select">
      <Dropdown.Trigger
        className={customTwMerge('border border-grayscale-16 rounded-lg items-center', props.className?.trigger)}
      >
        <Text as="span" size="md" className="text-grayscale-80 truncate">
          {currentCurrencySymbol}
        </Text>
        <div className="ml-auto">
          <AngleDown />
        </div>
      </Dropdown.Trigger>

      <Dropdown.Content className={props.className?.content}>
        {currencies.map((currency) => (
          <Dropdown.Item
            data-value={currency.apiID}
            key={currency.apiID}
            onSelect={() => setFieldValue('currency', currency.apiID)}
          >
            {currency.readableSymbol}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export default CurrencySelect;
