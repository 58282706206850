import React, { useCallback } from 'react';

import { exportCSVFile } from 'utils/src/ExportCSV';
import { Download as DownloadIcon } from '@benepass/icons';

import Button from './components/button';

import type { ItemType } from 'utils/src/ExportCSV';

type ExportProps = React.PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  onClick: () => unknown;
  dataTestId?: string;
}>;

export const ExportButton = ({ className, disabled, onClick, dataTestId, children }: ExportProps): JSX.Element => (
  <Button variant="secondary" className={className} disabled={disabled} onClick={onClick} data-testid={dataTestId}>
    {children || (
      <>
        <DownloadIcon size={26} />
      </>
    )}
  </Button>
);

type Props = React.PropsWithChildren<{
  headers: Array<ItemType>;
  items: Array<Array<ItemType>> | null | undefined;
  filename: string;
  className?: string;
  dataTestId?: string;
}>;

const ExportButtonSync = ({ headers, items, filename, className, dataTestId, ...props }: Props): JSX.Element => {
  const handleClick = useCallback(() => {
    if (items) {
      exportCSVFile(headers, items, filename);
    }
  }, [headers, items, filename]);

  return (
    <ExportButton onClick={handleClick} disabled={!items} className={className} dataTestId={dataTestId} {...props} />
  );
};

export default ExportButtonSync;
