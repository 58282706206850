import React, { useReducer, useMemo, createContext, useCallback, useContext } from 'react';

type Action = {
  type: 'SET_FOOTER';
  payload: React.ReactNode;
};

type State = {
  footer: React.ReactNode;
};

type Value = State & {
  setFooter: (footer: React.ReactNode) => void;
};

const initialState = {
  footer: null,
  setFooter: () => {
    // do nothing
  },
};

const Context = createContext<Value>(initialState);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FOOTER':
      return { ...state, footer: action.payload };

    default:
      return state;
  }
};

export const ModalProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setFooter = useCallback(
    (payload: React.ReactNode) => {
      dispatch({
        type: 'SET_FOOTER',
        payload,
      });
    },
    [dispatch]
  );
  const value: Value = useMemo(() => ({ ...state, setFooter }), [state, setFooter]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useModal = (): Value => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(`useModal must be used within a ModalProvider`);
  }

  return context;
};
