import React from 'react';

type SetValue<T> = (value: T) => void;

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    return undefined;
  }
}

const useLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const readValue = React.useCallback((): T => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch {
      // local storage not available
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = React.useState<T>(readValue);

  const setValue = React.useCallback(
    (value: T) => {
      if (typeof window !== 'undefined') {
        try {
          window.localStorage.setItem(key, JSON.stringify(value));
        } catch {
          // local storage not available
        }
      }
      setStoredValue(value);
    },
    [key]
  );

  React.useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  return [storedValue, setValue];
};

export default useLocalStorage;
