/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import type { PropsWithChildren } from 'react';

const { useState, useEffect, createContext, useContext } = React;

const defaultValue = {};
const ScreensContext = createContext(defaultValue);

type Props = PropsWithChildren<{
  screens: Record<string, unknown>;
}>;

// @see https://gist.github.com/ben-rogerson/b4b406dffcc18ae02f8a6c8c97bb58a8#file-minscreen-js
const MinScreenProvider = ({ children, screens }: Props) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mediaQueryLists = {} as { [key: string]: any };
    let isAttached = false;

    const mediaData = Object.entries(screens).map(([name, media]: [string, unknown]) => [
      name,
      `(min-width: ${media})`,
    ]);

    const handleQueryListener = () => {
      const updatedMatches = mediaData.reduce(
        (acc, [name]) => ({
          ...acc,
          [name!]: Boolean(mediaQueryLists[name!] && mediaQueryLists[name!].matches),
        }),
        {}
      );
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {} as { [key: string]: boolean };

      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') {
          matches[name!] = false;
          return;
        }
        mediaQueryLists[name!] = window.matchMedia(media);
        matches[name!] = mediaQueryLists[name!].matches;
      });

      setQueryMatch(matches);
      isAttached = true;

      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') return;
        mediaQueryLists[name!].addListener(handleQueryListener);
      });
    }

    return () => {
      if (!isAttached) return;
      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') return;
        mediaQueryLists[name!].removeListener(handleQueryListener);
      });
    };
  }, [screens]);

  return <ScreensContext.Provider value={queryMatch}>{children}</ScreensContext.Provider>;
};

export const useMinScreen = (): { min: (size: string | TemplateStringsArray) => boolean } => {
  const context = useContext<Record<string, boolean>>(ScreensContext);

  if (context === defaultValue) {
    throw new Error('useMinScreen must be used within a MinScreenProvider');
  }

  return { min: (size) => context[`${size}`] || false };
};

export default MinScreenProvider;
