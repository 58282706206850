import { classNames } from 'utils/src/classnames';
import twMerge from '../../utils/twMerge';
import type { Props, Variant } from '.';

const display1 = classNames(['text-display-1 font-bold']);
const display2 = classNames(['text-display-2 font-bold']);
const display3 = classNames(['text-display-3 font-bold leading-10']);
const display4 = classNames(['text-display-4 font-medium']);
const display5 = classNames(['text-display-5 font-medium']);

const header1 = classNames(['text-header-1 font-bold']);
const header2 = classNames(['text-header-2 font-medium']);
const header3 = classNames(['text-header-3 font-medium']);
const header4 = classNames(['text-header-4 uppercase font-medium']);
const header5 = classNames(['text-header-5 font-medium']);
const header6 = classNames(['text-header-6 uppercase font-medium']);
const header7 = classNames(['text-header-7 font-medium']);
const sm = classNames(['text-xs']);
const md = classNames(['text-sm']);
const lg = classNames(['text-base']);
const xl = classNames(['text-lg']);
const base = classNames(['text-grayscale-80']);

export const useTextClassNames = (props: Props): string => {
  const variantClasses: Record<Variant, string> = {
    'display-1': display1,
    'display-2': display2,
    'display-3': display3,
    'display-4': display4,
    'display-5': display5,
    'header-1': header1,
    'header-2': header2,
    'header-3': header3,
    'header-4': header4,
    'header-5': header5,
    'header-6': header6,
    'header-7': header7,
    body: base,
  };

  const size = {
    sm,
    md,
    lg,
    xl,
  };

  const classes = classNames([
    base,
    props.size ? size[props.size as keyof typeof size] : '',
    variantClasses[props.type as keyof typeof variantClasses],
    props.className ?? '',
  ]);

  return twMerge(classes);
};
