import { useCallback, useState } from 'react';
import { exportCSVFile } from 'utils/src/ExportCSV';

import { ExportButton } from './ExportButton';

import OverlayLoader from './OverlayLoader';
import toast from './components/toast';

import type { ItemType } from 'utils/src/ExportCSV';

type Props = {
  requestFunc: () => Promise<[Array<ItemType>, Array<Array<ItemType>>] | null | undefined>;
  loadingMessage: string;
  filename: string;
  className?: string;
  dataTestId?: string;
};

const ExportButtonAsync = ({ requestFunc, loadingMessage, filename, className, dataTestId }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const [headers, items] = (await requestFunc()) ?? [];

      if (headers && items) {
        exportCSVFile(headers, items, filename);
      }
    } catch {
      toast.show({ type: 'error', title: 'Error while exporting breakdown. Please try again or contact support.' });
    } finally {
      setIsLoading(false);
    }
  }, [requestFunc, filename]);

  return (
    <>
      {isLoading && <OverlayLoader message={loadingMessage} />}
      <ExportButton onClick={handleClick} disabled={isLoading} className={className} dataTestId={dataTestId} />
    </>
  );
};

export default ExportButtonAsync;
