import clsx from 'clsx';
import styles from './Card.module.css';

type Props = {
  children: JSX.Element;
  columns?: string;
  border?: boolean;
  margin?: string;
  padding?: string;
  className?: string;
};

function CardFooter(props: Props): JSX.Element {
  const { children, className, border = true, columns = '1fr 1fr', margin, padding } = props;
  return (
    <footer
      className={clsx(styles.footer, className, {
        [styles.border as string]: border,
      })}
      style={{
        gridTemplateColumns: columns,
        margin,
        padding,
      }}
    >
      {children}
    </footer>
  );
}

export default CardFooter;
