import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import { AuthenticationProvider } from './data/auth/transition';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { ThemeProvider } from 'react-components';
import AnalyticsContext from 'utils/src/context/AnalyticsContext';

import packageJSON from '../package.json';
import App from './app/app';
import * as serviceWorker from './serviceWorker';
import current from './config';

import 'react-components/src/utils/styles';
import './css/App.css';

if (current.track) {
  Sentry.init({
    dsn: current.sentry.dsn,
    release: `admindash@${packageJSON.version}`,
    environment: current.sentry.environment,
    attachStacktrace: true,
    denyUrls: ['localhost'],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        ignore: ['.ignore-me', '[data-replays-ignore]'],
        block: ['.block-me', '[data-replays-block]'],
        unblock: ['.unblock-me', '[data-replays-unblock]'],
        mask: ['.mask-me', '[data-replays-mask]'],
        unmask: ['.unmask-me', '[data-replays-unmaask]'],
      }),
    ],
    ignoreErrors: [/ResizeObserver/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
}

const Root = () => (
  <Sentry.ErrorBoundary fallback="An error has occured">
    <AnalyticsContext.Provider value={null}>
      <AuthenticationProvider
        redirectUri={window.location.origin}
        scope="openid profile email"
        cacheLocation="localstorage"
        {...current.auth}
      >
        <ThemeProvider>
          <StrictMode>
            <App />
          </StrictMode>
        </ThemeProvider>
      </AuthenticationProvider>
    </AnalyticsContext.Provider>
  </Sentry.ErrorBoundary>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);

serviceWorker.unregister();
