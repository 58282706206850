import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Loading from '../../Loading';
import UnknownError from '../unknown-error';

type Props = React.PropsWithChildren<{
  loader?: React.ReactNode;
  errorFallback?: React.ReactElement;
  onError?: (
    error: Error,
    info: {
      componentStack: string;
    }
  ) => void;
  hideError?: boolean;
}>;

const NullableComponent = () => null;

const SuspenseWrapper = ({
  children,
  loader = null,
  hideError = false,
  errorFallback,
  onError,
}: Props): JSX.Element => {
  const fallbackError = React.useMemo(() => {
    if (hideError) return <NullableComponent />;
    return errorFallback ?? <UnknownError />;
  }, [errorFallback, hideError]);

  return (
    <Suspense fallback={loader || <Loading />}>
      <ErrorBoundary fallback={fallbackError} onError={onError}>
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

export default SuspenseWrapper;
