import debounce from 'lodash.debounce';
import drop from 'lodash.drop';
import fromPairs from 'lodash.frompairs';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import mapValues from 'lodash.mapvalues';
import merge from 'lodash.merge';
import omitBy from 'lodash.omitby';
import startCase from 'lodash.startcase';
import truncate from 'lodash.truncate';
import uniqBy from 'lodash.uniqby';
import xor from 'lodash.xor';

export const downloadUrl = (url: string, filename?: string): void => {
  // @see https://stackoverflow.com/a/9834261
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename ?? '');
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noopener noreferrer');
  link.style.visibility = 'hidden';
  document.body?.appendChild(link);
  link.click();
  document.body?.removeChild(link);
};

export { debounce };
export { drop };
export { fromPairs };
export { isEqual };
export { isEmpty };
export { mapValues };
export { merge };
export { omitBy };
export { startCase };
export { truncate };
export { uniqBy };
export { xor };
