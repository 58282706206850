export const claimsConfig = {
  sentryDsn: 'https://122a18536bea46f39e7b1398efeb5794@o463927.ingest.sentry.io/6374737',
  auth: {
    domain: 'cognito.benefitsapi.com',
    client_id: '6l7jeu4r44kgndgeab4aot355m',
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT || 'https://claims.getbenepass.com/?v=2',
    client: process.env.REACT_APP_AUTH_CLIENT || 'claims',
    signOnUrl: process.env.REACT_APP_SIGN_ON_URL || 'https://signon.benefitsapi.com/',
  },
};

export const claimsFeatures = {
  claims: true,
};
