import { Environment, GraphQLResponse, Network, RecordSource, Store } from 'relay-runtime';
import type { RequestParameters, Variables, Environment as RelayEnvironment } from 'relay-runtime';

import { LogCategories, logCrumb, logError } from './Logs';

type Headers = { [key: string]: string };

type ErrorHandler = (error: Error) => unknown;

type Params = {
  graphQLUrl: string;
  getAccessTokenSilently: () => Promise<string>;
  email?: string | null;
  extraHeaders?: Headers;
  errorHandler?: ErrorHandler;
};

class NotAuthorizedError extends Error {
  constructor() {
    super('Not Authorized');
  }
}

const logResourceCrumb = (message: string, data?: { [key: string]: unknown }) => {
  logCrumb(LogCategories.UNKNOWN_GRAPHQL, message, data);
};

const logResourceError = (error: Error) => {
  if (!(error instanceof NotAuthorizedError)) {
    logError(LogCategories.UNKNOWN_GRAPHQL, error);
  }
};

const fetchGraphQL = ({ graphQLUrl, getAccessTokenSilently, email, extraHeaders = {}, errorHandler }: Params) => {
  return (operation: string | null | undefined, variables = {}): Promise<GraphQLResponse> => {
    return getAccessTokenSilently()
      .then((token) => {
        const headers = {
          ...extraHeaders,
          'Content-Type': 'application/json',
          Client_Code: 'webapp',
          User: email || '',
          Authorization: `Bearer ${token}`,
        };
        return fetch(graphQLUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            query: operation,
            variables,
          }),
        }).then(async (response) => {
          const res = await response.json();

          if (res?.errors?.length && errorHandler) {
            const errorHandlersPromises = (res.errors as Error[]).map(async (error) => errorHandler(error));
            await Promise.all(errorHandlersPromises);
          }

          if (!response.ok) {
            if (response.status === 401 || response.status === 403) throw new NotAuthorizedError();
            throw new Error(`Failed to fetch GraphQL, status: ${response.status}`);
          }

          return res;
        });
      })
      .catch(logResourceError);
  };
};

const fetchRelay = ({ graphQLUrl, getAccessTokenSilently, email, extraHeaders = {}, errorHandler }: Params) => {
  return (params: RequestParameters, variables: Variables): Promise<GraphQLResponse> => {
    logResourceCrumb(
      `posting query ${params.name} using ${String(params.text).replace(/\n/g, ' ')} with ${JSON.stringify(variables)}`
    );

    return fetchGraphQL({ graphQLUrl, getAccessTokenSilently, email, extraHeaders, errorHandler })(
      params.text,
      variables
    );
  };
};

export const createEnvironment = ({
  graphQLUrl,
  getAccessTokenSilently,
  email,
  extraHeaders = {},
  errorHandler,
}: Params): RelayEnvironment => {
  return new Environment({
    network: Network.create(fetchRelay({ graphQLUrl, getAccessTokenSilently, email, extraHeaders, errorHandler })),
    store: new Store(new RecordSource()),
  });
};

export default createEnvironment;
