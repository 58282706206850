import React, { useCallback, useState } from 'react';
import { MarginUtils } from 'utils';
import { indigo } from '@benepass/colors';
import styled from 'styled-components';
import Text from './components/text';
import tw from 'twin.macro';
import * as Modal from './components/modal';
import Flex, { FlexFlow, JustifyContent, AlignItems } from './Flex';
import Icon from './Icon';

export const StyledValue = styled(Text)`
  margin-left: 12px;
  text-align: right;
  overflow-wrap: anywhere;
  flex-shrink: 0;
  max-width: 70%;
`;
const EnhancedFlex = styled(Flex)<{ border?: boolean; enhancedMargin?: string; hasClick?: boolean }>`
  :not(:last-child) {
    padding-bottom: 16px;
    border-bottom: ${(props) => props.border && '1px solid #EAEAEA'};
    margin: ${(props) => props.enhancedMargin};
  }

  cursor: ${(props) => props.hasClick && 'pointer'};

  svg {
    cursor: pointer;
  }
`;
const ChildrenContainer = styled.div`
  ${tw`relative`}
`;
type Props = {
  children?: React.ReactNode;
  title: JSX.Element | string;
  border?: boolean;
  valueColor?: string;
  value?: JSX.Element | string;
  popupBody?: JSX.Element | string;
  popupTitle?: string;
  margin?: string;
  data?: unknown;
  onClick?: (arg0: unknown) => void;
  className?: string;
  valueStrikethrough?: boolean;
  dataTestId?: string;
};

const DetailItem = ({
  children,
  title,
  value,
  border = true,
  valueColor,
  popupBody,
  popupTitle,
  margin,
  onClick,
  data,
  className,
  valueStrikethrough = false,
  dataTestId,
}: Props): JSX.Element => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const handleOpenPopup = useCallback(() => {
    setPopupOpen(true);
  }, []);
  const handleClosePopup = useCallback(() => {
    setPopupOpen(false);
  }, []);
  const handleClick = useCallback(() => {
    onClick?.(data);
  }, [onClick, data]);
  const Value = useCallback(() => {
    if (!value) return null;

    if (typeof value === 'string') {
      return (
        // @ts-expect-error fixme
        <StyledValue forwardAs="p" color={valueColor || indigo[100]} strikethrough={valueStrikethrough}>
          {value}
        </StyledValue>
      );
    }

    return value;
  }, [value, valueColor, valueStrikethrough]);
  return (
    <>
      <EnhancedFlex
        enhancedMargin={margin || MarginUtils.BOTTOM_MED}
        border={border}
        flow={FlexFlow.COLUMN}
        onClick={handleClick}
        className={className}
        hasClick={!!onClick}
        dataTestId={dataTestId}
      >
        <Flex
          margin={children ? MarginUtils.BOTTOM_MED : MarginUtils.NONE}
          justifyContent={JustifyContent.SPACE_BETWEEN}
          className="title"
        >
          <Flex alignItems={AlignItems.CENTER}>
            {typeof title === 'string' ? (
              <Text className="break-words" type="header-3">
                {title}
              </Text>
            ) : (
              title
            )}

            {popupBody && <Icon name="Info" onClick={handleOpenPopup} />}
          </Flex>
          <Value />
        </Flex>
        <ChildrenContainer>{children}</ChildrenContainer>
      </EnhancedFlex>

      {popupBody && (
        <Modal.Root open={isPopupOpen} onOpenChange={handleClosePopup}>
          <Modal.Content>
            {popupTitle ? <Modal.Title>{popupTitle}</Modal.Title> : null}
            {popupBody}
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};

export default DetailItem;
