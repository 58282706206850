import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const StyledOverlayWrapper = styled.div`
  ${tw`bg-white`}
  ${tw`rounded-lg`}
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
`;
const StyledOverlay = styled.div`
  ${tw`rounded-lg`}
  ${tw`relative flex`}
  box-shadow: unset;
`;
const StyledShortcutsContainer = styled.div`
  ${tw`p-5 pr-3 bg-grayscale-2 rounded-l-lg`}
`;
const StyledCalendarContainer = styled.div<{ onlyNavbar?: boolean }>`
  ${tw`p-5 pl-3`}
  ${(props) => (props.onlyNavbar ? tw`pb-1` : '')}
  .DayPicker {
    min-height: 350px;
  }
`;
type Props = React.PropsWithChildren<{
  shortcuts?: JSX.Element;
  onlyNavbar?: boolean;
}>;

const DateRangePickerOverlay = ({ children, shortcuts, onlyNavbar }: Props): JSX.Element => {
  return (
    <StyledOverlayWrapper>
      <StyledOverlay>
        {shortcuts ? <StyledShortcutsContainer>{shortcuts}</StyledShortcutsContainer> : null}
        <StyledCalendarContainer onlyNavbar={onlyNavbar ?? false}>{children}</StyledCalendarContainer>
      </StyledOverlay>
    </StyledOverlayWrapper>
  );
};

export default DateRangePickerOverlay;
