import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import twMerge from '../../utils/twMerge';
import styled from 'styled-components';
import tw from 'twin.macro';
import React from 'react';

import { classNames } from 'utils/src/classnames';
import { AngleDown } from '@benepass/icons';
import { getChildName } from 'utils/src/react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const StyledTrigger = styled(PrimitiveAccordion.Trigger)`
  ${tw`w-full flex items-center`}

  .chevron {
    transition: transform 300ms;
  }

  &[data-state='open'] .chevron {
    transform: rotate(180deg);

    &.close {
      display: none;
    }
  }

  &[data-state='closed'] .chevron {
    &.open {
      display: none;
    }
  }
`;

const ChildrenContainer = styled.div`
  ${tw`flex flex-1 text-left`}
`;

const Icon = ({ children, ...props }: React.PropsWithChildren<React.HTMLProps<'div'>>) => {
  const moreThanOne = React.Children.toArray(children).length > 1;

  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (child && React.isValidElement(child)) {
          return React.cloneElement(child, {
            key: child.key,
            ...props,
            className: twMerge(
              child.props?.className || '',
              'chevron',
              // eslint-disable-next-line no-nested-ternary
              moreThanOne ? (index > 0 ? 'open' : 'close') : ''
            ),
          } as Partial<unknown>);
        }

        return null;
      })}
    </>
  );
};

Icon.displayName = 'BENEPASS_ACCORDION_HEADER_ICON';

export const Header = ({ children, className, ...props }: Props & PrimitiveAccordion.AccordionHeaderProps) => {
  const classes = classNames(['px-8 py-0 gap-6', className ?? '']);

  const IconElements = React.Children.toArray(children).filter((child) => getChildName(child) === Icon.displayName);
  const RestElements = React.Children.toArray(children).filter((child) => getChildName(child) !== Icon.displayName);

  return (
    <PrimitiveAccordion.Header {...props} className={twMerge(classes)}>
      <StyledTrigger>
        <ChildrenContainer>{RestElements}</ChildrenContainer>
        {IconElements.length ? (
          IconElements
        ) : (
          <AngleDown className="chevron self-center text-grayscale-100" aria-hidden size="24px" />
        )}
      </StyledTrigger>
    </PrimitiveAccordion.Header>
  );
};

Header.displayName = 'BENEPASS_ACCORDION_HEADER_ROOT';
Header.Icon = Icon;

export default Header;
