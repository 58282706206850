import customTwMerge from '../../utils/twMerge';
import Button from '../button';
import Text from '../text';
import React from 'react';
import { fromDateLocal } from 'utils/src/DateTime';
import { Calendar as CalendarIcon } from '@benepass/icons';
import { grayscale } from '@benepass/colors';

type Props = {
  from?: Date;
  to?: Date;
  single?: boolean;
  emptyTitle?: string;
  titleFormat?: string;
  disabled?: boolean;
  className?: string;
  textClassName?: string;
  isOpen?: boolean;
};

const formatDate = (date?: Date, titleFormat?: string) => {
  if (date) {
    return fromDateLocal(date).format(titleFormat);
  }

  return '';
};

const DateRangePickerInput = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      from,
      to,
      single = false,
      emptyTitle,
      disabled,
      titleFormat = 'MMM DD, YYYY',
      className,
      isOpen = false,
      ...rest
    },
    ref
  ) => {
    const title = React.useMemo(() => {
      if (from || to) {
        return single
          ? formatDate(from, titleFormat)
          : `${formatDate(from, titleFormat)} - ${formatDate(to, titleFormat)}`;
      }

      return emptyTitle ?? (single ? 'Select date' : 'Select date range');
    }, [from, to, emptyTitle, single, titleFormat]);

    const customClassName = React.useMemo(
      () => customTwMerge(`rounded-lg  border ${isOpen ? 'border-blue-100' : 'border-grayscale-16'}`, className),
      [className, isOpen]
    );

    return (
      <Button
        iconColor={(isOpen || !(!from && !to && !!emptyTitle)) && !disabled ? grayscale[80] : grayscale[48]}
        size="sm"
        variant="secondary"
        className={customClassName}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        <CalendarIcon size={20} />
        {title ? (
          <Text
            className={customTwMerge(
              `ml-1 ${!from && !to && !!emptyTitle ? 'text-grayscale-48' : ''}`,
              rest.textClassName || ''
            )}
          >
            {title}
          </Text>
        ) : null}
      </Button>
    );
  }
);

DateRangePickerInput.displayName = 'DateRangePickerInput';

export default DateRangePickerInput;
