import styled, { keyframes } from 'styled-components';
import { indigo } from '@benepass/colors';

const spinAnimation = keyframes`
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
`;
const Container = styled.div<Props>`
  width: ${(props) => props.size ?? '40px'};
  height: ${(props) => props.size ?? '40px'};
  display: inline-block;
  overflow: hidden;
  background: none;
`;
const Spinner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 8px solid ${indigo[100]};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
`;

type Props = {
  className?: string;
  size?: number;
};

const Spin = ({ className, size }: Props): JSX.Element => {
  return (
    <Container className={className} size={size}>
      <Spinner />
    </Container>
  );
};

export default Spin;
