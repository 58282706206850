import React from 'react';
import * as Modal from '../modal';
import Button from '../button';
import { useHistory, useLocation } from 'react-router-dom';
import customTwMerge from '../../utils/twMerge';

type ShowParams = {
  primaryButtonText?: string;
  primaryButtonCallback?: () => void;
  secondaryButtonText?: string;
  secondaryButtonCallback?: () => void;
  showClose?: boolean;
  content: JSX.Element;
  title?: string | JSX.Element;
  props?: {
    content?: React.ComponentProps<typeof Modal.Content>;
    className?: {
      title?: string;
      primaryButton?: string;
      secondaryButton?: string;
      buttonsContainer?: string;
    };
  };
};

type RefValue = {
  show: (params: ShowParams) => unknown;
  hide: () => unknown;
};

// Statically save the ref to the dialog provider
let staticRef: RefValue | null = null;

const DialogRoot = React.forwardRef((_, ref) => {
  const [dialog, setDialog] = React.useState<JSX.Element>();
  const history = useHistory();
  const location = useLocation();

  const queryValue = React.useMemo(() => new URLSearchParams(location.search).get('dialog') || '', [location.search]);

  const closeDialog = React.useCallback(() => {
    setDialog(undefined);
    const params = new URLSearchParams(location.search);
    params.delete('dialog');

    return history.replace({
      pathname: location.pathname,
      search: `?${params.toString()}`,
    });
  }, [history, location.pathname, location.search]);

  const handleClose = React.useCallback(
    (value: boolean) => {
      if (!value) closeDialog();
    },
    [closeDialog]
  );

  React.useEffect(() => {
    if (!queryValue) closeDialog();
  }, [queryValue, closeDialog]);

  React.useImperativeHandle(
    ref,
    () => ({
      show: (params: ShowParams) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.append('dialog', 'default');

        history?.push({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
        });
        setDialog(
          <Modal.Root open onOpenChange={handleClose}>
            <Modal.Content hideCloseButton={!params.showClose} {...(params.props?.content || {})}>
              {params.title ? <Modal.Title>{params.title}</Modal.Title> : null}
              {params.content}

              <div
                className={customTwMerge('flex flex-row justify-end gap-4', params.props?.className?.buttonsContainer)}
              >
                {params.secondaryButtonText ? (
                  <Button
                    className={params.props?.className?.secondaryButton || ''}
                    variant="secondary"
                    onClick={() => {
                      params.secondaryButtonCallback?.();
                      closeDialog();
                    }}
                  >
                    {params.secondaryButtonText}
                  </Button>
                ) : null}
                {params.primaryButtonText ? (
                  <Button
                    className={params.props?.className?.primaryButton || ''}
                    onClick={() => {
                      params.primaryButtonCallback?.();
                      closeDialog();
                    }}
                  >
                    {params.primaryButtonText}
                  </Button>
                ) : null}
              </div>
            </Modal.Content>
          </Modal.Root>
        );
      },
      hide: () => closeDialog(),
    }),
    [history, handleClose, closeDialog, location.pathname, location.search]
  );
  return <>{dialog}</>;
});

export const DialogProvider = () => {
  const actionRef = React.useRef<RefValue | null>(null);

  const setRef = React.useCallback((ref: RefValue | null) => {
    if (ref) {
      actionRef.current = ref;
      staticRef = ref;
    } else {
      staticRef = null;
    }
  }, []);

  return <DialogRoot ref={setRef} />;
};

DialogRoot.displayName = 'DialogRoot';

const dialog = {
  show: ({ showClose = true, ...params }: ShowParams) => staticRef?.show({ ...params, showClose }),
  hide: () => staticRef?.hide(),
};

export default dialog;
