import * as Sentry from '@sentry/react';

import SentryCategories from './enums/SentryCategories';

import type { SentryCategory } from './enums/SentryCategories';
import type { SeverityLevel } from '@sentry/react';

export const LogCategories = SentryCategories;

export const logCrumb = (
  category: SentryCategory,
  message: string | unknown,
  extraData?: { [key: string]: unknown }
): void => {
  const extraDataString = extraData ? JSON.stringify(extraData) : '';
  const text = `${JSON.stringify(message)}: ${extraDataString}`;
  Sentry.addBreadcrumb({
    category,
    message: text,
    level: 'info' as SeverityLevel,
  });
};

export const logError = (category: SentryCategory, error?: Error): void => {
  logCrumb(category, 'Error', { error });
  let newError = error;
  if (error instanceof Error) {
    logCrumb(category, 'Error message', {
      message: error.message,
      stack: error.stack,
    });
  } else {
    newError = new Error(JSON.stringify(error, null, 2));
  }

  Sentry.captureException(newError, {
    tags: { category },
  });
};

type ErrorHandlerFunction = (error: Error) => void;

export const errorHandler = (category: SentryCategory): ErrorHandlerFunction => {
  return (error: Error): void => {
    logError(category, error);
  };
};

export const logHandler = (category: SentryCategory): ErrorHandlerFunction => {
  return (error: Error): void => {
    logCrumb(category, error);
  };
};

export { logCrumb as log };
