import React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { Close, Warning, Info, CheckCircle } from '@benepass/icons';
import { grayscale, red, blue, green, yellow } from '@benepass/colors';
import Text from '../text';

type Props = {
  title?: string;
  description?: string;
  /** if a relay error is provided, the error message returned by it is added after the message */
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  duration?: number;
  type: 'warning' | 'info' | 'error' | 'success';
};

type RefValue = {
  show: (params: Props) => unknown;
  hide: () => unknown;
};

// Statically save the ref to the dialog provider
let staticRef: RefValue | null = null;

const ToastRoot = React.forwardRef((_, ref) => {
  const [toast, setToast] = React.useState<JSX.Element>();

  const containerStyle = (type: 'warning' | 'info' | 'error' | 'success') => {
    let color = 'bg-blue-10';
    let borderColor = 'border-blue-100';

    switch (type) {
      case 'warning':
        color = 'bg-yellow-10';
        borderColor = 'border-yellow-100';
        break;
      case 'success':
        color = 'bg-green-10';
        borderColor = 'border-green-100';
        break;
      case 'error':
        color = 'bg-red-10';
        borderColor = 'border-red-100';
        break;
      default:
        color = 'bg-blue-10';
        borderColor = 'border-blue-100';
        break;
    }

    return `flex flex-row gap-4 p-4 rounded-lg w-3/5 z-10 mx-auto items-center ${color} border ${borderColor}`;
  };

  const icon = (type: 'warning' | 'info' | 'error' | 'success') => {
    switch (type) {
      case 'error':
        return <Warning size={24} color={red[100]} />;
      case 'warning':
        return <Warning size={24} color={yellow[100]} />;
      case 'info':
        return <Info size={24} color={blue[100]} />;
      default:
        return <CheckCircle size={24} color={green[100]} />;
    }
  };

  React.useImperativeHandle(
    ref,
    () => ({
      show: (params: Props) => {
        const errorMessages =
          params.error?.source?.errors
            ?.map((current: Error) => {
              return current.message || null;
            })
            .filter(Boolean) ?? [];

        setToast(
          <ToastPrimitive.Provider key="toast-container">
            <ToastPrimitive.Toast
              className={containerStyle(params.type)}
              onOpenChange={(isOpen) => {
                if (!isOpen) setToast(undefined);
              }}
              duration={params.duration ?? 4000}
            >
              {icon(params.type)}
              <div className={`flex flex-col w-full ${params.description || errorMessages.length > 0 ? 'gap-1' : ''}`}>
                {params.title ? (
                  <ToastPrimitive.Title>
                    <Text type="header-3" color={grayscale[100]}>
                      {params.title}
                    </Text>
                  </ToastPrimitive.Title>
                ) : null}
                <ToastPrimitive.Description>
                  {params.description && (
                    <Text type="body" color={grayscale[80]}>
                      {params.description}
                    </Text>
                  )}
                  {errorMessages.length > 0 && (
                    <ul className="list-disc list-outside pl-8">
                      {errorMessages.map((message: string) => (
                        <li key={message}>{message}</li>
                      ))}
                    </ul>
                  )}
                </ToastPrimitive.Description>
              </div>

              <ToastPrimitive.Close>
                <Close color={grayscale[48]} />
              </ToastPrimitive.Close>
            </ToastPrimitive.Toast>
            <ToastPrimitive.Viewport className="fixed top-0 flex flex-col items-center justify-center p-4 list-none z-50 outline-none w-full" />
          </ToastPrimitive.Provider>
        );
      },
      hide: () => setToast(undefined),
    }),
    []
  );
  return <>{toast}</>;
});

ToastRoot.displayName = 'ToastRoot';

export const ToastProvider = (): React.ReactElement => {
  const actionRef = React.useRef<RefValue | null>(null);

  const setRef = React.useCallback((ref: RefValue | null) => {
    if (ref) {
      actionRef.current = ref;
      staticRef = ref;
    } else {
      staticRef = null;
    }
  }, []);

  return <ToastRoot ref={setRef} />;
};

const toast = {
  show: (params: Props): void => {
    staticRef?.show(params);
  },
  hide: (): void => {
    staticRef?.hide();
  },
};

export default toast;
