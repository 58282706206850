export const authenticatedFetch = async (
  token: string,
  endpoint: string,
  options: Partial<RequestInit> = {}
): Promise<Response> => {
  try {
    return await fetch(endpoint, {
      ...options,
      headers: {
        ...(options.headers || {}),
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch {
    return { ok: false } as Response;
  }
};
