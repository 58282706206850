import React from 'react';
import { ThemeProvider } from 'styled-components';

type Props = {
  children: JSX.Element;
};
// NOTE: Using the same breakpoints as tailwind
// https://tailwindcss.com/docs/breakpoints
const theme = {
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xll: '1536px',
  },
};

const EnhancedThemeProvider = ({ children }: Props): JSX.Element => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default EnhancedThemeProvider;
