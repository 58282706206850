/* eslint-disable react/prop-types */
import { useCallback } from 'react';
import { AuthenticationContext } from '../../data/auth/transition';
import { OverlayLoader, PageError, Message } from 'react-components';
import Button from 'react-components/src/components/button';
import Link from 'react-components/src/components/link';
import { useAdminCheck, Status } from '../../utils/admin';
import { openTicketLink } from 'utils/src/Support';
import config from '@config';

const { MessageTypes } = Message;
const ErrorPage = ({ logout, children, user }) => {
  const handleLogout = useCallback(() => {
    logout?.();
  }, [logout]);
  return (
    <PageError title="Invalid Email">
      {user?.email && (
        <p className="text-gray-500">
          Logged in as: <strong>{user.email}</strong>
        </p>
      )}
      <Message type={MessageTypes.ERROR}>
        That email isn’t registered with a Benepass account. Check for typos or please{' '}
        <Link size="md" className="underline" href={openTicketLink} shouldOpenNewTab>
          submit a ticket to our support
        </Link>
        .
      </Message>
      {children}
      <Button className="bg-red-100 justify-center" onClick={handleLogout}>
        Logout
      </Button>
    </PageError>
  );
};

const AdminChecker = ({ logout, error, children, user }) => {
  const { status } = useAdminCheck();
  if (status === Status.loading) {
    return <OverlayLoader message="Fetching user data..." />;
  }
  if (status === Status.success) {
    return children;
  }
  return (
    <ErrorPage logout={logout} user={user}>
      {error}
    </ErrorPage>
  );
};

const Authenticator = (props) => {
  const { loading } = props;

  return (
    <AuthenticationContext.Consumer>
      {({ isLoading, isAuthenticated, loginWithRedirect, logout, user }) => {
        const loadingComponent = loading || <OverlayLoader message="Authenticating user..." />;
        if (isLoading) {
          return loadingComponent;
        }
        if (!isAuthenticated) {
          if (!config.isOpsDash) {
            loginWithRedirect();
          }
          return loadingComponent;
        }
        return <AdminChecker {...props} user={user} logout={logout} />;
      }}
    </AuthenticationContext.Consumer>
  );
};

export default Authenticator;
