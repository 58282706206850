import axios from 'axios';
import { completePkceAuth, refreshCredentials, revokeToken, retrieveAndValidateState, parseJwtClaims } from '../pkce';
import config from '@config';

const { client_id, redirect_uri } = config.auth;
const { origin } = config.api;
const AUTH_CHECK_URL = `${origin}auth_check/`;

const checkAuthentication = async (context) => {
  const { 'benepass-user-id': userID } = parseJwtClaims(context.id_token);
  if (!userID || userID === 'None') {
    throw new Error('An account with your email was not found.');
  }
  try {
    const response = await axios.get(AUTH_CHECK_URL, { headers: { authorization: `Bearer ${context.id_token}` } });
    if (response.status !== 200) {
      throw new Error('Authentication check failed.');
    }
  } catch (e) {
    throw new Error('Authentication check failed.');
  }
};

const getTokenFromCodeExchange = (context) => {
  const { code, state } = context.url.query;
  const session = retrieveAndValidateState(state);
  return completePkceAuth({ client_id, code, session, redirect_uri });
};

const getTokenFromRefreshToken = (context) => refreshCredentials({ client_id, refresh_token: context.refresh_token });

const revokeRefreshToken = (context) => revokeToken({ client_id, token: context.refresh_token });

export default { checkAuthentication, getTokenFromCodeExchange, getTokenFromRefreshToken, revokeRefreshToken };
