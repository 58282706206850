import styles from './Card.module.css';

type Props = {
  children: JSX.Element;
  columns?: string;
  margin?: string;
};

const CardGroup = ({ children, columns, margin }: Props): JSX.Element => {
  const style = {
    gridTemplateColumns: columns,
    margin,
  };
  return (
    <div style={style} className={styles.cardGroup}>
      {children}
    </div>
  );
};

export default CardGroup;
