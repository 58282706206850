import React, { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';
import { NumberFormatUtils } from 'utils';

import Tooltip from '../../../Tooltip';
import Flex, { AlignItems } from '../../../Flex';
import Text from '../../text';

const { formatCurrency } = NumberFormatUtils;

type Props = {
  amount: number | string | null;
  currency?: string;
  notAvailableText?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  type?: React.ComponentProps<typeof Text>['type'];
  lineThrough?: boolean;
  hideCurrency?: boolean;
  className?: React.ComponentProps<typeof Text>['className'];
  translate?: 'yes' | 'no' | undefined;
};

const TableColumnAmount = ({
  amount,
  currency = '',
  notAvailableText = '(not disclosed)',
  size,
  type = 'body',
  lineThrough,
  hideCurrency,
  translate,
  ...props
}: Props): JSX.Element => {
  const amountFormatted = useMemo(
    () => (typeof amount === 'number' ? formatCurrency(amount, true, currency) : amount),
    [amount, currency]
  );
  const [sign, amountString] = useMemo(() => {
    if (!amountFormatted?.startsWith('+') && !amountFormatted?.startsWith('-')) {
      return ['', amountFormatted];
    }
    return [amountFormatted.substring(0, 1), amountFormatted.substring(1).trim()];
  }, [amountFormatted]);

  if (amount === null) {
    return (
      <Flex alignItems={AlignItems.BASELINE}>
        <Tooltip content="This information has been hidden to protect your employee's private health information.">
          <span>
            <Text type="body">{notAvailableText ?? ''}</Text>
          </span>
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Flex alignItems={AlignItems.BASELINE}>
      <Text
        type={type}
        size={size}
        className={twMerge(`font-medium ${lineThrough ? 'line-through' : ''}`, props.className)}
        translate={translate}
      >
        {sign && <span className={sign === '-' ? 'text-red-100' : 'text-green-100'}>{sign}</span>}
        {amountString}
      </Text>
      {!hideCurrency && currency && (
        <Text type="body" size={size} className="ml-1 text-grayscale-48" translate={translate}>
          {currency?.toUpperCase()}
        </Text>
      )}
    </Flex>
  );
};

export default TableColumnAmount;
