import React from 'react';

import { MarginUtils } from 'utils';
import { Warning } from '@benepass/icons';
import { red } from '@benepass/colors';

import Text from './components/text';

import Flex, { FlexFlow } from './Flex';

type Props = React.PropsWithChildren<{
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  optional?: boolean;
  margin?: (typeof MarginUtils)[keyof typeof MarginUtils];
  dataTestId?: string;
}>;

const FormLabel = ({
  children,
  label,
  hasError = false,
  errorMessage = '',
  optional = false,
  margin,
  dataTestId,
}: Props): JSX.Element => (
  <Flex flow={FlexFlow.COLUMN} margin={margin || MarginUtils.BOTTOM_MED} dataTestId={dataTestId}>
    {label && (
      <Text as="label" margin={!optional ? MarginUtils.BOTTOM_NORMAL : undefined} className="text-grayscale-100">
        {label}
      </Text>
    )}
    {optional && <Text className="mb-2 text-grayscale-48">(Optional)</Text>}

    {children}
    {hasError && (
      <span className="mt-2 text-red-100 flex gap-1 items-center">
        <Warning color={red['100']} size={16} />
        {errorMessage}
      </span>
    )}
  </Flex>
);

export default FormLabel;
