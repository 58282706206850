import { assign, send } from 'xstate';
import { parseJwtClaims, makePkceState, retrieveAndValidateState } from '../pkce';
import {
  now,
  loadRefreshToken,
  readAndClearUrlParams,
  keyForState,
  storeRefreshToken,
  clearRefreshToken,
} from '../utils';
import config from '@config';
import { mapValues } from 'utils/src/core-utils';

const { client, signOnUrl } = config.auth;

const clearContext = assign((context) => {
  const { idp } = context.url.query;
  const cleared = mapValues(context, (value, key) => (key === 'error' ? value : null));
  return { ...cleared, idp };
});

const updateContext = assign((context, event) => event.data);
const updateContextFromUrlFragment = assign((context) => {
  try {
    retrieveAndValidateState(context.url.query.state);
    return context.url.fragment;
  } catch (err) {
    return {};
  }
});
const updateUserInfo = assign((context) => {
  const { email, sub } = parseJwtClaims(context.id_token);
  return { user: { email, name: email, sub } };
});
const updateErrorMessageFromEvent = assign((context, event) => {
  const error = event.data.message;
  return { error };
});
const initializeContext = assign(() => ({
  url: readAndClearUrlParams(),
  refresh_token: loadRefreshToken(),
}));
const run = send('authenticate');

const startAuthentication = async ({ error, idp }) => {
  const { authUrl, pkceState } = await makePkceState({
    timestamp: now(),
    ttlSeconds: 600,
    size: 128,
    signOnUrl,
    client,
    error,
    idp,
  });
  const key = keyForState(pkceState.state);
  window.localStorage.setItem(key, JSON.stringify(pkceState));
  window.location.href = authUrl;
};

const checkAccessTokenExpiryAfterDelay = send('checkTokenExpiry', { delay: 5000 });
const saveRefreshToken = (context) => storeRefreshToken(context.refresh_token);

export default {
  clearContext,
  updateContext,
  updateContextFromUrlFragment,
  updateUserInfo,
  updateErrorMessageFromEvent,
  initializeContext,
  startAuthentication,
  checkAccessTokenExpiryAfterDelay,
  saveRefreshToken,
  run,
  clearRefreshToken,
};
