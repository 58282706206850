export const assureNumber = (number: number): number => {
  if (Number.isNaN(number)) {
    return 0;
  }

  if (typeof number === 'number') {
    return number;
  }

  return 0;
};

export const formatCurrency = (number: number, showCents = true, currency?: string | null): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ?? 'USD',
    maximumSignificantDigits: showCents ? undefined : 3,
  }).format(assureNumber(number));
};
