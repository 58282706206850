import { now } from '../utils';

const haveRefreshToken = (context) => !!context.refresh_token;
const tokensInUrlFragment = (context) => !!context.url.fragment.id_token && !!context.url.fragment.refresh_token;
const pkceStateInUrl = (context) => context.url.query.code && context.url.query.state;
const tokenExpiryUpcoming = (context) => {
  return context.expiry <= now() + 60;
};

export default { haveRefreshToken, tokensInUrlFragment, pkceStateInUrl, tokenExpiryUpcoming };
