import { openTicketLink } from 'utils/src/Support';
import Text from '../text';
import Link from '../link';
import { XCircle as ErrorIcon } from '@benepass/icons';
import Tooltip from '../../Tooltip';

const UnknownError = (): JSX.Element => {
  return (
    <div className="rounded bg-red-10 p-4 border border-red-40">
      <div className="flex flex-row items-center">
        <div className="mr-4">
          <ErrorIcon size={32} margin={0} className="text-red-100" />
        </div>
        <Text as="p" type="header-2">
          Error
        </Text>
      </div>
      <div className="flex flex-row">
        <div className="mr-4 w-8" />
        <Text className="flex flex-wrap items-center gap-1">
          Something went wrong when trying to fetch data. Please try to <u>hard refresh</u>{' '}
          <Tooltip content="To perform a browser hard refresh on macOS use cmd + shift + R. If you are using windows or linux, use ctrl + shift + R" />{' '}
          your browser. If that doesn&apos;t resolve the issue, please{' '}
          <Link size="md" href={openTicketLink} shouldOpenNewTab>
            submit a ticket
          </Link>
          .
        </Text>
      </div>
    </div>
  );
};

export default UnknownError;
