import React from 'react';
import LayoutLoginContainer from './LayoutLoginContainer';
import LayoutLoginContentBox from './LayoutLoginContentBox';

type Props = React.PropsWithChildren<{
  title: string;
}>;

const PageError = ({ title, children }: Props) => (
  <LayoutLoginContainer>
    <LayoutLoginContentBox title={title}>{children}</LayoutLoginContentBox>
  </LayoutLoginContainer>
);

export default PageError;
