import UnknownError from 'react-components/src/components/unknown-error';
import AnalyticsContext from 'utils/src/context/AnalyticsContext';

import { useContext, useEffect, lazy, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { LogCategories, logHandler } from 'utils/src/Logs';
import { ErrorBoundary } from 'react-error-boundary';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import config from '@config';

import type { routesQuery as QueryType } from './__generated__/routesQuery.graphql';

const AdminRoutes = lazy(() => import('./admin'));
const ClaimsRoutes = lazy(() => import('./claims'));
const OpsRoutes = lazy(() => import('./ops'));

const query = graphql`
  query routesQuery {
    res: admin__Identity {
      apiID
      fullName
      email
    }
  }
`;

const Wrapper = () => (
  <ErrorBoundary FallbackComponent={() => <UnknownError />} onError={logHandler(LogCategories.UNKNOWN_GRAPHQL)}>
    <Navigation />
  </ErrorBoundary>
);

const routes = (() => {
  if (config.isClaims) return <ClaimsRoutes />;
  if (config.isOpsDash) return <OpsRoutes />;

  return <AdminRoutes />;
})();

const Navigation = () => {
  const { res: adminViewer } = useLazyLoadQuery<QueryType>(query, {});
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);
  const [previousUrl, setPreviousUrl] = useState<string>(location.pathname);

  useEffect(() => {
    if (location.pathname !== previousUrl) {
      analytics?.page(location.pathname, {
        search: location.search,
        previousPath: previousUrl,
      });

      setPreviousUrl(location.pathname);
    }
  }, [location, analytics, previousUrl]);

  useEffect(() => {
    analytics?.identify(adminViewer?.apiID, {
      email: adminViewer?.email,
      name: adminViewer?.fullName,
    });
  }, [analytics, adminViewer]);

  return <Switch>{routes}</Switch>;
};

export default Wrapper;
