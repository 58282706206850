import styled from 'styled-components';
import Spin from './Spin';

const Container = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height ?? '250px'};
  width: ${(props) => props.width ?? '250px'};
`;
type Props = {
  className?: string;
  height?: string;
  width?: string;
};

const Loading = ({ className, height = '250px', width = '100%' }: Props): JSX.Element => {
  return (
    <Container className={className} height={height} width={width}>
      <Spin />
    </Container>
  );
};

export default Loading;
