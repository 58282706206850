import React, { useEffect, useImperativeHandle } from 'react';
import confetti from 'canvas-confetti';

export type ConfettiRef = { fire: () => void };

type Props = {
  forwardRef?: React.ForwardedRef<ConfettiRef>;
};

let interval: NodeJS.Timeout | null = null;

const fire = () => {
  const duration = 2000;
  const animationEnd = Date.now() + duration;
  const defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 99999,
  };

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }

    const particleCount = 50 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti({
      ...defaults,
      particleCount,
      origin: {
        x: randomInRange(0.1, 0.3),
        y: Math.random() - 0.2,
      },
    });
    confetti({
      ...defaults,
      particleCount,
      origin: {
        x: randomInRange(0.7, 0.9),
        y: Math.random() - 0.2,
      },
    });
    return null;
  }, 250);
};

const Confetti = ({ forwardRef }: Props): JSX.Element | null => {
  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  useImperativeHandle(
    forwardRef,
    () => ({
      fire,
    }),
    []
  );

  return null;
};

const ConfettiWithRef = React.forwardRef<ConfettiRef>((props, ref) => <Confetti {...props} forwardRef={ref} />);

ConfettiWithRef.displayName = 'Confetti';

export default ConfettiWithRef;
