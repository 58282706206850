import React from 'react';
import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import styled from 'styled-components';

import { classNames } from 'utils/src/classnames';

import DynamicHeightContainer from '../../ui/layout/container/animated-dynamic-height.containter';
import customTwMerge from '../../utils/twMerge';

const StyledContent = styled(PrimitiveAccordion.Content)`
  &[data-state='closed'] {
    display: none;
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  animate?: boolean;
};

export const Content = ({ children, className, animate, ...props }: Props): JSX.Element => {
  const Wrapper = React.useMemo(() => (animate ? DynamicHeightContainer : React.Fragment), [animate]);

  return (
    <Wrapper>
      <StyledContent {...props} className={customTwMerge(classNames(['py-6 px-8 flex flex-col', className ?? '']))}>
        {children}
      </StyledContent>
    </Wrapper>
  );
};
